svg * {
    fill: currentcolor;
}

.svg-icon {
    display: inline-block;
    inline-size: 100%;
    block-size: 100%;
    fill: currentcolor;
}
