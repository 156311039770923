.agenda {
    display: block;
    margin: 0 auto;
    padding: 4rem;
    box-sizing: border-box;

    width: 100%;
    height: 100%;

    background-color: var(--color-general);

    .carrousel {
        background-color: var(--color-general);
    }
}

.agenda__masthead {
    padding: 0;
    height: 8%;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        margin: 0;
    }

    h1 {
        margin: 0;
        font-family: var(--font-rebellious);
        font-size: 7rem;
        letter-spacing: -.4rem;
        color: var(--color-purple);
        position: relative;
        display: inline-block;
    }
}

.agenda__carrousel {
    height: 90%;
}

.agenda__header {
    text-align: right;
    text-transform: uppercase;
    color: var(--color-purple);
    font-weight: var(--font-weight-regular);
    font-family: var(--font-general);
    font-size: calc(8px + 0.7vw);

    position: absolute;
    top: 0;
    right: 0;
    padding-right: .56rem;

    @media screen and (min-width: 768px) {
        padding-right: 1.44rem;
    }
}

.agenda-item {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 10%;
    overflow: hidden;
    border-bottom: .1rem dotted var(--color-border-dark);

    &:last-child {
        border: 0;
    }
}

.agenda-item__content {
    float: left;
    width: 75%;
    height: 100%;
    padding: .56rem;

    @media screen and (min-width: 768px) {
        padding: .72rem 1.44rem;
    }

    box-sizing: border-box;
    font-size: 0;
    line-height: 1.25;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.agenda-item__date {
    font-family: var(--font-general);
    font-size: calc(14px + 1vw);
    font-weight: var(--font-weight-regular);
    line-height: 1;
    text-transform: uppercase;
    margin: 0 0 .39rem 0;
}

.agenda-item__title {
    margin: 0;
    display: inline;
    font-family: var(--font-general);
    font-size: calc(21px + 1.5vw);
    line-height: 1;

    @media screen and (min-width: 768px) {
        line-height: 1.15;
    }

    font-weight: var(--font-weight-medium);
    text-transform: uppercase;
}

.agenda-item__timing {
    margin: 0;
    display: inline;
    font-family: var(--font-general);
    font-size: calc(14px + 1vw);
    line-height: 1;

    @media screen and (min-width: 768px) {
        line-height: 1.15;
    }

    font-weight: var(--font-weight-regular);
    text-transform: uppercase;
}

.agenda-item__tickets {
    float: left;
    width: 25%;
    height: 100%;

    padding: .56rem;

    @media screen and (min-width: 768px) {
        padding: .72rem 1.44rem;
    }

    text-align: right;
    box-sizing: border-box;

    font-size: calc(8px + 0.7vw);
    color: var(--color-purple);

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.agenda-item__price {
    font-weight: var(--font-weight-medium);
    color: var(--color-purple);
    font-size: calc(11px + 1vw);
    font-family: var(--font-general);
    display: block;
    margin-bottom: .4rem;

    svg {
        width: 1.17rem;
        height: 1.17rem;
        line-height: 1;
        vertical-align: middle;
        position: relative;
        top: -.17rem;
        display: none;
    }

    &.-sold_out, &.-full {
        color: var(--color-black);

        svg {
            display: inline-block;
        }
    }
}

.agenda__footer {
    position: absolute;
    bottom: 4rem;
    right: 4rem;
    font-size: 3rem;
    z-index: 10;
    line-height: 1;
}
