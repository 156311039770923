.image-list {
    padding: 3rem 0 1rem;

    @media (--screen-full) {
        padding: 5rem 0 7rem;
    }

    .site-container {
        position: relative;
    }
}

.image-list .image-list__items {
    margin: 3rem 0 !important;
    padding: 0 !important;

    img {
        width: 100%;
        max-width: 100vw;

        @media (--screen-large) {
            max-width: 96rem;
        }

    }
}

.image-list__title {
    font-size: 2.9rem;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    color: var(--color-purple);
    display: block;

    @media (--screen-large) {
        font-size: 3.5rem;
    }

    @media (--screen-full) {
        margin-bottom: 2rem;
    }
}

.image-list__arrows {
    position: absolute;
    top: .8rem;
    right: 2rem;
}

.image-list__arrows .image-list__arrow {
    background: var(--color-purple);
    border-radius: 50%;
    width: 4.2rem;
    height: 4.2rem;
    display: inline-block;
    cursor: pointer;
    transition: opacity .3s ease;
    outline: none;
    border: 0;
    opacity: 1;
    position: static;
    transform: translate(0);
    margin-left: .7rem;

    &::before {
        display: none;
    }

    &:--focus {
        opacity: .5 !important;
        background: var(--color-purple);
    }
}
