.text {
    margin: 0 0 4rem;

    img {
        max-width: 100%;
        max-height: 75rem;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    &.-border {
        padding: 4rem 0;
        border-top: .1rem dotted var(--color-border-dark);
        border-bottom: .1rem dotted var(--color-border-dark);
        margin: 0;

        + .text, .artist__list + & {
            border-top: 0;
        }
    }

    &.-left, &.-right {
        @media (--screen-large) {
            display: flex;
        }
    }

    .page-detail__content__info & {
        margin: 0 auto 4rem auto;

        &.-button {
            margin-top: 4rem;
        }
    }

    .page-detail__content__info.-full & {
        max-width: 128rem;
    }
}

.text__content {
    flex-basis: 50%;
    margin-bottom: 3rem;
}

.text__img {
    box-sizing: border-box;
    padding: 0;

    @media (--screen-large) {
        flex-basis: 50%;
    }

    img {
        max-width: 100%;
        max-height: 75rem;
    }

    .text.-left & {
        order: -1;
        @media (--screen-large) {
            padding: 0 3rem 0 0;
        }
    }

    .text.-right & {
        @media (--screen-large) {
            padding: 0 0 0 3rem;
        }
    }
}

.raw-html-embed, .text {
    iframe {
        width: 100%;
    }
}
