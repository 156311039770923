.oEmbedMedia {
    position: relative;

    display: block;
    width: 100%;
    height: auto;
    margin: 0 auto;
    margin-bottom: 2rem;

    &[data-type="video"] {
        height: 0;
        padding-bottom: 56.25%; /*16:9*/

        iframe {
            position: absolute;
            top: 0;
            left: 0;

            display: block;
            width: 100%;
            height: 100%;
        }
    }
}
