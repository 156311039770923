.pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 4rem 0;

    .content-cards__container ~ .site-container .pagination & {
        margin-top: -3rem;

        @media (--screen-large) {
            margin-top: 0;
        }

        @media (--screen-full) {
            margin-top: 4rem;
        }
    }
}

.pagination__container {
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
    background-color: var(--color-white);
    border-radius: 2.5rem;

    &.-desktop {
        display: none;

        @media (--screen-large) {
            display: inline-flex;
        }
    }

    &.-mobile {
        @media (--screen-large) {
            display: none;
        }
    }

    .body.-abatnight & {
        background-color: transparent;
    }
}

.pagination__item {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    height: 5rem;
    margin: 0 .5rem;
    color: var(--color-purple);
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    font-size: 1.5rem;
    line-height: 1;

    .body.-abatnight & {
        color: var(--color-abatnight-white);
    }

    a {
        color: var(--color-purple);
        text-decoration: none;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }

        .body.-abatnight & {
            color: var(--color-abatnight-white);
        }
    }

    &:not(.-spacer) {
        &.-current,
        &:hover {
            color: var(--color-purple);

            .body.-abatnight & {
                color: var(--color-abatnight-white);
            }

            a {
                color: var(--color-purple);

                .body.-abatnight & {
                    color: var(--color-abatnight-white);
                }
            }
        }

        &.-current {
            a {
                text-decoration: underline;
            }
        }
    }

    &.-prev,
    &.-next {
        padding: 0;
        margin: 0 2rem;
        position: relative;
        z-index: 1;
        font: 0/0 sans-serif;
        transition: opacity .3s ease;

        &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: -1;
            width: 5rem;
            height: 5rem;
            background-color: var(--color-purple);
            border-radius: 50%;
            transform: translate3d(-50%, -50%, 0);
            content: '';

            .body.-abatnight & {
                background-color: var(--color-abatnight);
            }
        }

        a {
            position: absolute;
            top: 0;
            left: -1.4rem;
            z-index: 1;
            display: block;
            width: 5rem;
            height: 5rem;
            color: var(--color-general);
        }

        &::after {
            color: var(--color-general);
            content: '';
            transition: background .3s ease;

            display: inline-block;
            width: 2.3rem;
            height: 2.3rem;

            background: url(arrow.svg) center center no-repeat;
            background-size: 2.3rem 2.3rem;
            pointer-events: none;

            @supports (mask-repeat: no-repeat) {
                background: currentColor;
                mask-image: url(arrow.svg);
                mask-repeat: no-repeat;
                mask-size: 2.3rem 2.3rem;
            }
        }

        &:hover,
        &:focus {
            opacity: .6;
        }
    }

    &.-disabled {
        opacity: .2 !important;
        pointer-events: none;
    }

    &.-prev {
        &::after {
            transform: rotate(180deg);
        }
    }
}
