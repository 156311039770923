.partners-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        text-align: center;
        flex-basis: 10rem;

        @media (--screen-medium-large) {
            flex-basis: 14rem;
        }
    }

    img {
        inline-size: revert-layer;
        block-size: revert-layer;
    }
}

.partners-item {
    display: inline-block;
    box-sizing: border-box;
    padding: 1rem;
    opacity: .6;
    transition: opacity .3s ease;

    .body.-abatnight & {
        opacity: .8;
    }

    .text & {
        opacity: 1;
    }

    @media (--screen-medium-large) {
        padding: 1rem 1.8rem;
    }

    @media (--screen-large) {
        padding: 1rem 2.8rem;
    }

    &:hover,
    &:focus {
        opacity: 1;

        .body.-abatnight & {
            opacity: 1;
        }
    }

    .text & img {
        max-width: 10rem !important;
    }
}
