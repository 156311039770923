.row {
    margin: 0;
    padding-inline: 2rem;

    &.-size-full {
        padding: 0;
    }

    &[data-margin='none'] {
        margin: 0;
    }

    &[data-margin='block'] {
        margin-block: 4rem;
    }

    &[data-margin='top'] {
        margin-block: 4rem 0;
    }

    &[data-margin='bottom'] {
        margin-block: 0 4rem;
    }

    &[data-size='small'] {
        max-inline-size: 75rem;
        margin-inline: auto;
    }

    &[data-block-type='child-pages'] {
        background-color: var(--color-infocard);
    }

    &[id] {
        scroll-margin-block-start: 10rem;
    }
}

.row__sizer {
    max-inline-size: 100%;
    margin-inline: auto;
}

.row__inner {
    inline-size: 75rem;
    max-inline-size: 100%;
    margin-inline: auto;

    .pagecontent-row-container.-align-left &, .row.-align-left & {
        margin-inline-start: 0;
    }

    .row.-size-medium & {
        inline-size: 75rem;
    }

    .row.-size-wide & {
        inline-size: 128rem;
    }

    .row.-size-full & {
        inline-size: 100%;
    }
}
