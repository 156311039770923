@use '../../components/container/container.scss';

.row[data-block-type='card-grid'] {
    background-color: var(--color-general);
    background-position: center;
    background-size: cover;

    &[data-theme='fun'] {
        background-image: url('../../images/bg-fun.png');
    }

    &[data-theme='family'] {
        background-image: url('../../images/bg-family.png');
    }

    &[data-theme='rebellious'] {
        background-image: url('../../images/bg-rebellious.png');
    }

    &[data-theme='well-informed'] {
        background-image: url('../../images/bg-well-informed.png');
    }
}

.card-grid {
    padding-block: 3rem;

    @media (--screen-from-lg) {
        padding-block: 7rem;
    }
}

.card-grid__inner {
    inline-size: 100%;
    margin-inline: auto;

    max-inline-size: 128rem;
    padding-inline: var(--side-padding);

    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    justify-content: center;
    box-sizing: border-box;

    @media (--screen-from-lg) {
        gap: 5rem;
    }

    &.-empty {
        justify-content: flex-start;
    }
}
