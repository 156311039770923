.carrousel__wrapper {
    position: relative;
}

.concert__carrousel {
    width: 100%;
    height: 100%;
}

.carrousel {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 1s ease-out;
    opacity: 1;

    &.-hidden {
        opacity: 0;
        overflow: hidden;
        z-index: 2;
    }

    &.-justannounced {
        &::after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-size: auto 100%;
            background-repeat: no-repeat;
            z-index: 10;
        }

        &.-family {
            background-color: var(--color-family);
            &::after {
                background-image: url('../../images/justannounced-family.gif');
            }
        }

        &.-fun {
            background-color: var(--color-fun);
            &::after {
                background-image: url('../../images/justannounced-fun.gif');
            }
        }

        &.-rebellious {
            background-color: red;
            &::after {
                background-image: url('../../images/justannounced-rebellious.gif');
            }
        }

        &.-well-informed {
            background-color: var(--color-well-informed);
            &::after {
                background-image: url('../../images/justannounced-wellinformed.gif');
            }
        }
    }
}

.carrousel__cover {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(25deg,rgba(33,32,30,.8),rgba(33,32,30,.5),rgba(33,32,30,0),rgba(33,32,30,.3));
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .carrousel.-justannounced & {
        width: 100%;
        height: 56%;
        top: 0;
        right: 0;
        left: auto;
        bottom: auto;

        @media (orientation: landscape) {
            width: 92%;
            height: 78%;
        }
    }
}

.carrousel__content {
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    padding: 5%;
    left: 0;
    bottom: 0;
    font-size: calc(18px + 2vw);
    line-height: 1.25;
    color: var(--color-white);
    text-shadow: 0 0 36px rgba(17,17,17,.3);
    font-family: var(--font-general);

    p {
        margin-top: 0;
    }

    .carrousel.-justannounced & {
        z-index: 3;
        padding: 0;
        height: 39%;

        @media (orientation: landscape) {
            height: calc(22% + 11rem);
        }
    }
}

.concert__date {
    color: var(--color-white);
    font-family: var(--font-general);
    font-size: calc(24px + 2vw);
    font-weight: var(--font-weight-regular);
    line-height: 1;
    text-transform: uppercase;
    margin: 0;

    small {
        display: block;
        margin:.56rem 0;
        @media screen and (min-width: 768px) {
            position: relative;
            margin: 0;
            display: inline-block;
            top: -.56rem;
            margin-left: .56rem;
        }
        text-transform: none;
        font-size: calc(22px + 1vw);
        line-height: 1;
        font-family: var(--font-general);
        svg {
            line-height: 1;
            fill: var(--color-white);
            width: 2.28rem;
            height: 2.28rem;
            vertical-align: middle;
        }
    }

    .carrousel.-justannounced & {
        font-size: 5rem;
        margin: 0;
        padding: 2rem 5rem;
        color: var(--color-purple);
        background-color: var(--color-purple);
        display: inline-block;
        height: 11rem;
        box-sizing: border-box;

        @media (orientation: landscape) {
            text-align: center;
            font-size: 7rem;
            line-height: 7rem;
            margin-left: 17.5rem;
        }
    }

    .carrousel.-fun & {
        @media (orientation: landscape) {
            color: var(--color-fun);
        }
    }

    .carrousel.-family & {
        @media (orientation: landscape) {
            color: var(--color-family);
        }
    }

    .carrousel.-rebellious & {
        @media (orientation: landscape) {
            color: red;
        }
    }

    .carrousel.-well-informed & {
        @media (orientation: landscape) {
            color: var(--color-well-informed);
        }
    }
}

.concert__title {
    margin: 2.5rem 0 0;
    color: var(--color-white);
    font-family: var(--font-general);
    font-size: calc(29px + 6vw);
    line-height: 1;

    font-weight: var(--font-weight-medium);

    .carrousel.-justannounced & {
        color: var(--color-purple);
        font-weight: var(--font-weight-regular);
        font-size: 8rem;
        margin: 3rem 0 0 0;
        padding: 0;
        overflow: hidden;
        display: flex;

        span {
            display: none;

            &:first-child {
                display: inline-block;
            }

            @media (orientation: landscape) {
                display: inline-block;
            }
        }

        @media (orientation: landscape) {
            margin: 0;
            font-size: 10rem;
            transform: translate(0, 3rem);
        }
    }

    &.-fun, .carrousel.-fun & {
        font-family: var(--font-fun);
        letter-spacing: .05rem;
    }

    &.-family, .carrousel.-family & {
        font-family: var(--font-family);
        letter-spacing: -.3rem;
    }

    &.-rebellious, .carrousel.-rebellious & {
        font-family: var(--font-rebellious);
        letter-spacing: -.4rem;
    }

    &.-well-informed, .carrousel.-well-informed & {
        font-family: var(--font-well-informed);
    }
}

.concert__title__inner {
    padding: 0 5rem;

    span {
        @media (orientation: landscape) {
            white-space: nowrap;
        }
    }

    @media (orientation: landscape) {
        padding: 0 10rem 0 0;
        display: flex;
        line-height: 17rem;
        vertical-align: middle;
        animation-name: marquee;
        animation-duration: 120s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-delay: .1s;
        animation-fill-mode: both;
        gap: 10rem;
        white-space: nowrap;
        transform: translateX(0);

        &[data-speed='1'] {
            animation-duration: 50s;
        }

        &[data-speed='2'] {
            animation-duration: 60s;
        }

        &[data-speed='3'], &[data-speed='4'], &[data-speed='5'], &[data-speed='6'] {
            animation-duration: 70s;
        }
    }
}

.concert__timing {
    margin: 0;
    color: var(--color-white);
    font-family: var(--font-general);
    font-size: calc(24px + 2vw);
    line-height: 1;

    @media screen and (min-width: 768px) {
        line-height: 1.15;
    }

    font-weight: var(--font-weight-regular);
}

.concert__slogan {
    margin: 0;
    color: var(--color-white);
    padding-top: 1.11rem;
    width: 70%;

    @media screen and (min-width: 768px) {
        padding-top: 2.22rem;
    }
}

.concert__button {
    position: absolute;
    bottom: 6rem;
    right: 6rem;
    border-radius: 10rem;
    border: .5rem solid var(--color-white);
    width: 25%;
    text-align: center;
    font-size: 5rem;
    padding: 1rem;
}

.concert__aside {
    margin: 0;
    padding-top: 1.11rem;

    @media screen and (min-width: 768px) {
        padding-top: 2.22rem;
    }

    & > img {
        max-width: 10rem;
        height: auto;
        object-fit: cover;
    }
}

.carrousel__video {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: none;

    &.-portrait {
        @media (orientation: portrait) {
            display: block;
        }
    }

    &.-landscape {
        @media (orientation: landscape) {
            display: block;
        }
    }

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top left;
    }
}

.carrousel__footer {
    position: absolute;
    bottom: 5rem;
    left: 5rem;
    right: 5rem;
    color: var(--color-purple);
    justify-content: space-between;
    display: flex;

    @media (orientation: landscape) {
        display: none;
    }
}

@keyframes marquee {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

.carrousel__title {
    font-size: 8rem;
    padding: 2rem 5rem;
    color: var(--color-purple);
    background-color: var(--color-purple);
    position: absolute;
    line-height: 1.25;
    z-index: 12;
    top: 6rem;
    left: 0;
    width: 65rem;
    box-sizing: border-box;

    .carrousel.-justannounced.-family & {
        font-family: var(--font-family);
        color: var(--color-family);
        font-size: 9rem;
        line-height: 1;
    }

    .carrousel.-justannounced.-fun & {
        font-family: var(--font-fun);
        color: var(--color-fun);
        letter-spacing: .05rem;
    }

    .carrousel.-justannounced.-rebellious & {
        font-family: var(--font-rebellious);
        letter-spacing: -.4rem;
        color: red;
        font-size: 9rem;
        line-height: 1;
    }

    .carrousel.-justannounced.-well-informed & {
        font-family: var(--font-well-informed);
        color: var(--color-well-informed);
        font-size: 11rem;
        line-height: 1;
    }
}
