figure.image {
    margin: 2rem 0;

    figcaption {
        font-size: 1.6rem;
        text-align: left;
    }

    img {
        max-width: 100%;
        max-height: 75rem;
    }
}
