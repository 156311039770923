body {
    position: relative;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    width: 100%;
    min-width: 32rem;
    min-height: 100%;
    margin: 0 auto;

    background-color: var(--color-white);
    word-wrap: break-word; // Don't let long words break the layout on small area's

    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    font-size: 1.8rem;
    line-height: 1.5;
    overflow-x: hidden;

    color: var(--color-purple);

    -webkit-font-smoothing: antialiased;

    &.-no-overflow {
        position: fixed;
        overflow: hidden;
        height: 100%;

        /* Prevent iOS safari from still showing main content */
        @supports (-webkit-overflow-scrolling: touch) {
            main {
                opacity: 0;
            }
        }
    }

    &.-abatnight {
        background: var(--color-abatnight-body) url('../../images/bg-abatnight.png') no-repeat top center;
        background-size: cover;
        background-attachment: fixed;
        color: var(--color-abatnight-white);
    }
}
