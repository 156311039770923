.alert {
    text-align: left;
    background-color: var(--color-purple);
    color: var(--color-general);
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    font-size: 2.1rem;
    display: block;
    margin: 6rem 0;
    border-radius: 10rem;
    padding: 1rem 1rem 1rem 8rem;
    position: relative;
    transition: all .3s ease, transform .6s ease, all 1s ease;

    &.-hidden {
        opacity: 0;
        margin: 0;
        padding: 0;
        transform: scale(0);
    }

    &.-family {
        background-color: var(--color-family);
        color: var(--color-purple);
    }

    &.-fun {
        background-color: var(--color-fun);
        color: var(--color-purple);
    }

    &.-rebellious {
        background-color: var(--color-rebellious);
        color: var(--color-purple);
    }

    &.-error {
        padding: 2rem;

        .poll__content & {
            padding: 1.5rem;
            margin-bottom: 0;
        }
    }

    &.-button {
        padding: 1.5rem;
        margin: 0 0 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 1rem;
        text-align: center;

        @media (--screen-large) {
            border-radius: 10rem;
            padding: 1rem 1rem 1rem 3rem;
            flex-direction: row;
            justify-content: left;
            text-align: left;
        }

        .button {
            margin-top: 1rem;

            @media (--screen-large) {
                margin-top: 0;
                margin-left: auto;
            }
        }

        span {
            color: var(--color-white);
        }

        &:has(.alert__close) {
            padding-left: 8rem;
        }
    }
}

.alert__close {
    position: absolute;
    top: 1.6rem;
    left: 2.8rem;
    width: 2rem;
    height: 2rem;
    border: 0;
    font: 0/0 sans-serif;
    cursor: pointer;

    background-color: var(--color-general);
    background-image: url(cross.svg);
    background-size: 2rem 2rem;

    transition: opacity .3s ease;

    .alert.-family &,
    .alert.-fun &,
    .alert.-rebellious & {
        background-color: var(--color-purple);
    }

    @supports (mask-repeat: no-repeat) {
        background: var(--color-general);
        mask-image: url(cross.svg);
        mask-repeat: no-repeat;
        mask-size: 2rem 2rem;

        .alert.-family &,
        .alert.-fun &,
        .alert.-rebellious & {
            background: var(--color-purple);
        }
    }

    &:hover,
    &:focus {
        opacity: .6;
    }
}
