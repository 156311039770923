.video__container {
    display: block;
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    margin-bottom: 3rem;

    @media (--screen-mini-small) {
        width: calc(100% + 4rem);
    }

    .page-detail__content__info &, .event-detail__content & {
        max-width: 75rem;
        margin: 0 auto 3rem;

        @media (--screen-mini-small) {
            margin-left: -2rem;
            margin-right: -2rem;
        }

        @media (--screen-large) {
            padding-top: calc(75rem * (9/16));
        }
    }

    button {
        outline: none;
    }
}

.video, .video__cover {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    z-index: 0;
    padding: 0;
}

.video__cover {
    z-index: 1;
    text-align: center;
    cursor: pointer;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        border: .7rem solid var(--color-general);
        border-radius: 50%;
        width: 8rem;
        height: 8rem;
        margin: -4.7rem 0 0 -4.7rem;
        line-height: 8rem;
        transition: opacity .3s ease;
        text-indent: 1rem;

        @media (--screen-full) {
            border: 1rem solid var(--color-general);
            width: 12rem;
            height: 12rem;
            margin: -7rem 0 0 -7rem;
            line-height: 12rem;
            text-indent: 1.5rem;
        }

        .event-detail__content.-family & {
            border-color: var(--color-family);
        }

        .event-detail__content.-fun & {
            border-color: var(--color-fun);
        }

        .event-detail__content.-rebellious & {
            border-color: var(--color-rebellious);
        }

        .event-detail__content.-well-informed & {
            border-color: var(--color-well-informed);
        }
    }

    &:hover,
    &:focus {
        .icon {
            opacity: .6;
        }
    }

    .video__container.-playing & {
        display: none;
    }
}

.video {
    display: none;

    .video__container.-playing & {
        display: block;
    }
}

.fb-video {
    margin-bottom: 3rem;

    .page-detail__content__info &, .event-detail__content & {
        max-width: 75rem;
        margin: 0 auto 3rem;

        @media (--screen-mini-small) {
            display: inline-block;
            width: calc(100% + 4rem) !important;
            margin-left: -2rem;
            margin-right: -2rem;
        }
    }

    span, iframe {
        width: 100%!important;
    }
}
