.site-container {
    display: block;
    width: 100%;
    max-width: 128rem;
    margin: 0 auto;
    padding: 0 var(--side-padding);
    box-sizing: border-box;

    &.-content-cards {
        padding: 0;

        @media (--screen-large) {
            padding: 0 var(--side-padding);
        }
    }

    &.-search {
        padding-top: 4rem;
    }

    &.-bottom {
        @media (--screen-full) {
            padding-bottom: 10rem;
        }
    }

    &.-team {
        padding: 0;
    }
}
