.page-header {
    display: block;
    width: 100%;
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    box-sizing: border-box;
    background-color: var(--color-general);
    transition: background-color .3s ease;
    z-index: 3;

    .site-container {
        display: flex;
        align-items: center;
        height: 7rem;
    }

    html:not([data-scroll='0']) &:not(.-with-anchors), html.-fixed &:not(.-with-anchors) {
        position: fixed;
    }

    &.-fun {
        background-color: var(--color-fun);
    }

    &.-family {
        background-color: var(--color-family);
    }

    &.-rebellious {
        background-color: var(--color-rebellious);
    }

    &.-well-informed {
        background-color: var(--color-well-informed);
    }

    &.-myab {
        background-color: var(--color-purple);
    }

    &.-with-anchors {
        height: 12rem;
        background-color: var(--color-purple);

        @media (--screen-medium-large) {
            height: 17.8rem;
        }
    }

    .body.-abatnight &, &.-abatnight {
        background-color: var(--color-abatnight-body);
        box-shadow: 0 .5rem 2.5rem 0 var(--color-abatnight);
    }
}

.page-header__right {
    margin-left: auto;
}

.page-header__link {
    font-family: var(--font-general);
    font-size: 1.8rem;
    color: var(--color-purple);
    text-decoration: none;
    margin: 0 1.5rem;
    transition: opacity .3s ease;

    &:last-child {
        margin-right: 0;
    }

    &.-search {
        font: 0/0 sans-serif;
        vertical-align: middle;
    }

    &.-program, &.-search {
        display: none;

        @media (--screen-large) {
            display: inline-block;
        }
    }

    &:hover,
    &:focus {
        opacity: .6;
    }

    .page-header.-myab &,
    .page-header.-with-anchors & {
        color: var(--color-general);
    }

    .body.-abatnight &, .page-header.-abatnight & {
        color: var(--color-abatnight-white);
    }
}

span.page-header__link {
    margin-right: 0;
}

.page-header__logo {
    position: absolute;
    top: .7rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;

    .page-header.-myab &,
    .page-header.-with-anchors & {
        background: var(--color-purple);
        border-radius: 50%;
        padding: .5rem;
        box-shadow:
            inset 0 0 0 .7rem var(--color-purple),
            inset 0 0 0 1.4rem var(--color-general);
    }
}

.page-header__left {
    @media (--screen-large) {
        display: flex;
        align-items: center;
    }
}

.page-header__liveurope {
    display: none;
    opacity: .6;
    margin-left: 1.5rem;

    @media (--screen-large) {
        display: inline-block;
    }

    img {
        inline-size: revert-layer;
        block-size: revert-layer;
    }

    .body.-abatnight &, .page-header.-abatnight & {
        opacity: 1;
        img {
            content: url('../partners/liveurope.png');
        }

        &:hover, &:focus {
            opacity: .6;
        }
    }
}
