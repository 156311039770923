.browser-update, .no-script {
    background: #CCCCCC;
    color: #000000;
    display: none;
    margin: 0;
    padding: 1rem 2rem;
    height: 5rem;
    line-height: 1.5;
    text-align: center;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    z-index: 9999;

    /* stylelint-disable csstools/media-use-custom-media */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block;
    }
    /* styleline-enable */
}

.no-script {
    display: block;
}

noscript ~ .browser-update {
    top: 5rem;
}
