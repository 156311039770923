.faq {
    .page-detail__content__info & {
        max-width: 75rem;
        margin: 0 auto 4rem auto;
    }
}

.faq__list {
    margin: 0;
    padding: 0;
    list-style: none;
    border-top: .1rem solid var(--color-border-dark);
}

.faq__list__item {
    margin: 0;
    border-bottom: .1rem solid var(--color-border-dark);
    padding: 3rem 0;
}

.faq-item__link {
    text-decoration: none !important;
    outline: none;
}

.faq-item__trigger {
    display: none;
}

.faq-item__question {
    display: block;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    font-size: 2.5rem;
    color: var(--color-purple);
    cursor: pointer;
    transition: opacity .3s ease;
    padding-left: 5rem;
    position: relative;

    &:hover, &:focus {
        opacity: .6;
    }
}

.faq-item__content {
    max-height: 0;
    overflow: hidden;
    transition: max-height .3s ease, padding .6s ease;

    :not(li):first-child {
        margin-top: 0;
        padding-top: 0;
    }

    :not(li):last-child {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .faq-item.-open &, [data-gjs-type='wrapper'] & {
        max-height: none;
    }
}

.faq-item__button {
    background: transparent;
    border: 0;
    border-radius: 50%;
    display: inline-block;
    width: 4rem;
    height: 4rem;
    position: absolute;
    top: -.2rem;
    left: 0;
    outline: none;
    transition: all .3s ease;
    text-align: center;
    font: 0/4rem sans-serif;

    .faq-item__trigger:checked + .faq-item__question & {
        transform: rotate(180deg);
    }
}
