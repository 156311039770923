.marquee {
    font-size: 3rem;

    position: relative;
    z-index: 0;

    display: block;

    font-family: var(--font-general);
    line-height: 1.5;
    color: var(--color-purple-dark);
    text-decoration: none !important;
    letter-spacing: .04rem;

    [data-color='white'] & {
        color: var(--color-white);
    }
}

.marquee__background {
    position: absolute;
    z-index: -1;
    inset: 0;

    background-image: url('pattern-rebellious.png');
    background-repeat: repeat-x;
    background-position: center;
    background-size: cover;

    [data-animation] & {
        animation: animated-background 14s infinite linear;
    }

    [data-theme='fun'] & {
        background-image: url('pattern-fun.png');
    }

    [data-theme='family'] & {
        background-image: url('pattern-family.png');
    }

    .marquee:--focus & {
        animation-play-state: paused;
    }

    @media (prefers-reduced-motion) {
        animation: none;
    }
}

.marquee__inner {
    display: flex;
    align-items: flex-start;
    min-block-size: 6.5rem !important;
}

.node-marquee__el {
    display: flex !important; // needed to show after
    padding-block: 1.5rem;

    &::after {
        content: '•';
        display: inline-block;
        margin-inline: 1rem;
    }

    p {
        max-inline-size: none;
        margin: 0;
    }
}

@keyframes animated-background {
    from {
        background-position: 0 0;
    }

    to {
        background-position: -1920px 0;
    }
}
