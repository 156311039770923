.row[data-block-type='call-to-action'] {
    display: flex;
    align-items: center;
    min-height: 40rem;

    background-color: var(--color-general);
    background-position: center;
    background-size: cover;

    &[data-theme='fun'] {
        background-image: url('fun.png');
    }

    &[data-theme='family'] {
        background-image: url('family.png');
    }

    &[data-theme='rebellious'] {
        background-image: url('rebellious.png');
    }

    &[data-theme='well-informed'] {
        background-image: url('well-informed.png');
    }
}

.call-to-action {
    margin-block: 3.5rem;
    padding: 3rem 1.9rem;
}

.call-to-action__title {
    text-align: center;
}

.call-to-action__content {
    font-size: 1.8rem;
    line-height: 1.55;
    text-align: center;

    p {
        text-align: center !important;
    }

    &:last-child {
        margin-block-end: 0;
    }
}

.call-to-action__actions {
    display: flex;
    justify-content: center;
    margin-block-start: 3rem;
}

@media (--screen-from-sm) {
    .call-to-action {
        margin-block: 4rem;
        padding: 5.5rem 7rem;
    }
}

@media (--screen-from-lg) {
    .call-to-action {
        margin-block: 6rem;
        padding: 5.5rem 3rem;
    }
}

@media (--screen-from-xxl) {
    .call-to-action {
        padding: 9rem 6rem;
    }
}
