@font-face {
    font-family: 'Graebenbach';
    src: url('Graebenbach/Graebenbach-Bold.eot');
    src: url('Graebenbach/Graebenbach-Bold.eot?#iefix') format('embedded-opentype'),
         url('Graebenbach/Graebenbach-Bold.woff') format('woff'),
         url('Graebenbach/Graebenbach-Bold.ttf') format('truetype');
    font-weight: var(--font-weight-bold);
    font-style: normal;
}

@font-face {
    font-family: 'Graebenbach';
    src: url('Graebenbach/Graebenbach-Medium.eot');
    src: url('Graebenbach/Graebenbach-Medium.eot?#iefix') format('embedded-opentype'),
         url('Graebenbach/Graebenbach-Medium.woff') format('woff'),
         url('Graebenbach/Graebenbach-Medium.ttf') format('truetype');
    font-weight: var(--font-weight-medium);
    font-style: normal;
}

@font-face {
    font-family: 'Graebenbach';
    src: url('Graebenbach/Graebenbach-Regular.eot');
    src: url('Graebenbach/Graebenbach-Regular.eot?#iefix') format('embedded-opentype'),
         url('Graebenbach/Graebenbach-Regular.woff') format('woff'),
         url('Graebenbach/Graebenbach-Regular.ttf') format('truetype');
    font-weight: var(--font-weight-regular);
    font-style: normal;
}

@font-face {
    font-family: 'Harbour';
    src: url('Harbour/Harbour-Bold.woff2') format('woff2'),
         url('Harbour/Harbour-Bold.woff') format('woff');
    font-weight: var(--font-weight-bold);
    font-style: normal;
}

@font-face {
    font-family: 'MediaSans';
    src: url('MediaSans/MediaSans-Ultra.woff2') format('woff2'),
         url('MediaSans/MediaSans-Ultra.woff') format('woff');
    font-weight: var(--font-weight-extra-bold);
    font-style: normal;
}

@font-face {
    font-family: 'Souvenir';
    src: url('Souvenir/Souvenir-Light.woff2') format('woff2'),
         url('Souvenir/Souvenir-Light.woff') format('woff');
    font-weight: var(--font-weight-light);
    font-style: normal;
}

@font-face {
    font-family: 'Spoof';
    src: url('Spoof/Spoof-Medium.eot');
    src: url('Spoof/Spoof-Medium.eot?#iefix') format('embedded-opentype'),
         url('Spoof/Spoof-Medium.woff2') format('woff2'),
         url('Spoof/Spoof-Medium.woff') format('woff');
    font-weight: var(--font-weight-medium);
    font-style: normal;
}

