.menu-button {
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0 1.5rem 0 0;
    padding: 0;
    overflow: visible;
}

.menu-button:hover {
    opacity: 0.7;
}

.menu-button.-active:hover {
    opacity: 0.7;
}

.menu-button.-active .menu-button__hamburger-inner,
.menu-button.-active .menu-button__hamburger-inner::before,
.menu-button.-active .menu-button__hamburger-inner::after {
    background-color: #000;
}

.menu-button__hamburger {
    width: 1.6rem;
    height: 1.2rem;
    display: inline-block;
    position: relative;
}

.menu-button__hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -.2rem;
}

.menu-button__hamburger-inner, .menu-button__hamburger-inner::before, .menu-button__hamburger-inner::after {
    width: 1.6rem;
    height: .15rem;
    background-color: var(--color-purple);
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;

    .page-header.-myab &  {
        background-color: var(--color-general);
    }

    .body.-abatnight &, .page-header.-abatnight & {
        background-color: var(--color-abatnight-white);
    }
}

.menu-button__hamburger-inner::before, .menu-button__hamburger-inner::after {
    content: "";
    display: block;
}

.menu-button__hamburger-inner::before {
    top: -.6rem;
}

.menu-button__hamburger-inner::after {
    bottom: -.6rem;
}

/*
* Spin
*/
.menu-button .menu-button__hamburger-inner {
    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.menu-button .menu-button__hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.menu-button .menu-button__hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.menu-button.-active .menu-button__hamburger-inner {
    transform: rotate(225deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.menu-button.-active .menu-button__hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.menu-button.-active .menu-button__hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}
