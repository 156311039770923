.iframe {
    margin: 3rem 0 5rem;
}

.iframe__frame {
    iframe {
        inline-size: 100%;
        border: none;

        &[src='about:blank'] {
            background-color: var(--color-general);
        }
    }

    // only in pagebuilder
    [data-gjs-type='wrapper'] & {
        inline-size: 100%;
        min-block-size: 45rem;
        background-color: var(--color-general);
    }
}
