.icon {
    position: relative;
    vertical-align: middle;
    font: 0/0 sans-serif;
    display: inline-flex;

    align-items: center;
    justify-content: center;

    &.-hidden {
        display: none;
    }

    &::before {
        content: '';
        transition: background .3s ease;

        display: inline-flex;
        width: 2.8rem;
        height: 2.8rem;

        align-items: center;
        justify-content: center;

        background-color: var(--color-purple);

        background-size: 2.8rem 2.8rem;

        .event-detail__content.-abatnight &, .body.-abatnight .page-header &, .page-header.-abatnight &, .body.-abatnight .page-footer__social & {
            background-color: var(--color-abatnight-white);
        }

        .event-detail__content.-abatnight .event-detail__content__aside & {
            background-color: var(--color-abatnight-body);
        }

        @supports (mask-repeat: no-repeat) {
            background: var(--color-purple);
            mask-repeat: no-repeat;
            mask-size: 2.8rem 2.8rem;

            .event-detail__content.-abatnight &, .body.-abatnight .page-header &, .page-header.-abatnight &, .body.-abatnight .page-footer__social &  {
                background: var(--color-abatnight-white);
            }

            .event-detail__content.-abatnight .event-detail__content__aside & {
                background: var(--color-abatnight-body);
            }
        }
    }

    &.-facebook::before {
        width: 1.5rem;

        background-image: url(facebook.svg);
        background-size: 1.5rem 2.8rem;

        .cta__list & {
            background-color: var(--color-general);
        }

        .event-detail__content__aside & {
            width: 1.2rem;
            height: 2.2rem;
            background-size: 1.2rem 2.2rem;
        }

        .artist__socials__item & {
            width: 1.3rem;
            height: 2.5rem;
            background-size: 1.3rem 2.5rem;
        }

        .button.-social & {
            background-color: var(--color-white);
        }

        @supports (mask-repeat: no-repeat) {
            mask-image: url(facebook.svg);
            mask-size: 1.5rem 2.8rem;

            .cta__list & {
                background: var(--color-general);
            }

            .event-detail__content__aside & {
                mask-size: 1.2rem 2.2rem;
            }

            .artist__socials__item & {
                mask-size: 1.3rem 2.5rem;
            }

            .button.-social & {
                background: var(--color-white);
            }
        }
    }

    &.-twitter::before {
        width: 2.8rem;
        height: 2.5rem;

        background-image: url(twitter.svg);
        background-size: 2.8rem 2.5rem;

        .artist__socials__item & {
            width: 2.6rem;
            height: 2.2rem;

            background-size: 2.6rem 2.2rem;
        }

        @supports (mask-repeat: no-repeat) {
            mask-image: url(twitter.svg);
            mask-size: 2.8rem 2.5rem;

            .artist__socials__item & {
                mask-size: 2.6rem 2.2rem;
            }
        }
    }

    &.-instagram::before {
        background-image: url(instagram.svg);

        .artist__socials__item & {
            width: 2.5rem;
            height: 2.5rem;

            background-size: 2.5rem 2.5rem;
        }

        @supports (mask-repeat: no-repeat) {
            mask-image: url(instagram.svg);

            .artist__socials__item & {
                mask-size: 2.5rem 2.5rem;
            }
        }
    }

    &.-youtube::before {
        background-image: url(youtube.svg);

        @supports (mask-repeat: no-repeat) {
            mask-image: url(youtube.svg);
        }
    }

    &.-spotify::before {
        background-image: url(spotify.svg);

        .cta__list & {
            background-color: var(--color-general);
        }

        .artist__socials__item & {
            width: 2.5rem;
            height: 2.5rem;

            background-size: 2.5rem 2.5rem;
        }

        .button.-social & {
            width: 4rem;
            height: 4rem;;

            background-size: 4rem 4rem;
            background-color: var(--color-spotify);
        }

        @supports (mask-repeat: no-repeat) {
            mask-image: url(spotify.svg);

            .cta__list & {
                background: var(--color-general);
            }

            .artist__socials__item & {
                mask-size: 2.5rem 2.5rem;
            }

            .button.-social & {
                mask-size: 4rem 4rem;
                background: var(--color-spotify);
            }
        }
    }

    &.-website::before {
        background-image: url(website.svg);

        .artist__socials__item & {
            width: 2.5rem;
            height: 2.5rem;

            background-size: 2.5rem 2.5rem;
        }

        @supports (mask-repeat: no-repeat) {
            mask-image: url(website.svg);

            .artist__socials__item & {
                mask-size: 2.5rem 2.5rem;
            }
        }
    }

    &.-bandcamp::before {
        background-image: url(bandcamp.svg);

        .artist__socials__item & {
            width: 2.5rem;
            height: 2.5rem;

            background-size: 2.5rem 2.5rem;
        }

        @supports (mask-repeat: no-repeat) {
            mask-image: url(bandcamp.svg);

            .artist__socials__item & {
                mask-size: 2.5rem 2.5rem;
            }
        }
    }

    &.-deezer::before {
        background-image: url(deezer.svg);

        .artist__socials__item & {
            width: 2.5rem;
            height: 2.5rem;

            background-size: 2.5rem 2.5rem;
        }

        @supports (mask-repeat: no-repeat) {
            mask-image: url(deezer.svg);

            .artist__socials__item & {
                mask-size: 2.5rem 2.5rem;
            }
        }
    }

    &.-soundcloud::before {
        background-image: url(soundcloud.svg);

        .artist__socials__item & {
            width: 2.5rem;
            height: 2.5rem;

            background-size: 2.5rem 2.5rem;
        }

        @supports (mask-repeat: no-repeat) {
            mask-image: url(soundcloud.svg);

            .artist__socials__item & {
                mask-size: 2.5rem 2.5rem;
            }
        }
    }

    &.-tiktok::before {
        background-image: url(tiktok.svg);

        .artist__socials__item & {
            width: 2.5rem;
            height: 2.5rem;

            background-size: 2.5rem 2.5rem;
        }

        @supports (mask-repeat: no-repeat) {
            mask-image: url(tiktok.svg);

            .artist__socials__item & {
                mask-size: 2.5rem 2.5rem;
            }
        }
    }

    &.-search::before {
        width: 1.6rem;
        height: 1.6rem;

        background-image: url(search.svg);
        background-size: 1.6rem 1.6rem;

        .page-header.-myab &, .page-nav__header & {
            background-color: var(--color-general);
        }

        @supports (mask-repeat: no-repeat) {
            mask-image: url(search.svg);
            mask-size: 1.6rem 1.6rem;

            .page-header.-myab &, .page-nav__header & {
                background: var(--color-general);
            }
        }
    }

    &.-cross::before {
        width: 1.1rem;
        height: 1.1rem;

        background-image: url(cross.svg);
        background-size: 1.1rem 1.1rem;

        .audio-player & {
            background-color: var(--color-purple);
            width: 2rem;
            height: 2rem;
            background-size: 2rem 2rem;
        }

        .filter__link & {
            background-color: var(--color-purple);

            .body.-abatnight & {
                background-color: var(--color-abatnight-white);
            }
        }

        .audio-player:hover &, .audio-player:focus & {
            background-color: var(--color-general);
        }

        @supports (mask-repeat: no-repeat) {
            mask-image: url(cross.svg);
            mask-size: 1.1rem 1.1rem;

            .audio-player & {
                background: var(--color-purple);
                mask-size: 2rem 2rem;
            }

            .filter__link & {
                background: var(--color-purple);

                .body.-abatnight & {
                    background: var(--color-abatnight-white);
                }
            }

            .audio-player:hover &, .audio-player:focus & {
                background: var(--color-general);
            }
        }
    }

    &.-arrow-left::before,
    &.-arrow-right::before {
        width: 2.1rem;
        height: 2.1rem;

        background-image: url(arrow-right.svg);
        background-size: 2.1rem 2.1rem;

        .button &, .content-cards__arrow &, .image-list__arrow &, .content-card__info__summary & {
            width: 1.4rem;
            height: 1.4rem;
            background-size: 1.4rem 1.4rem;
            background-color: var(--color-general);
        }

        .content-cards__arrow &, .jumbotron__arrow &, .image-list__arrow &, .content-card__info__summary & {
            width: 1.2rem;
            height: 1.2rem;
            background-size: 1.2rem 1.2rem;
        }

        .content-cards__container.-purple .content-cards__arrow &, .content-card__info__summary &, .carrousel__footer &, .agora-header__info &, .agenda__footer & {
            background-color: var(--color-purple);
        }

        .button.-general & {
            background-color: var(--color-purple);

            .body.-abatnight .wrapper.-purple & {
                background-color: var(--color-abatnight-white);
            }
        }

        @supports (mask-repeat: no-repeat) {
            mask-image: url(arrow-right.svg);
            mask-size: 2.1rem 2.1rem;

            .button &,
            .content-cards__arrow &, .image-list__arrow &, .content-card__info__summary & {
                background: var(--color-general);
                mask-size: 1.4rem 1.4rem;
            }
            .button.-general & {
                background: var(--color-purple);

                .body.-abatnight .wrapper.-purple & {
                    background: var(--color-abatnight-white);
                }
            }
            .body.-abatnight .wrapper.-purple .button:hover &,
            .body.-abatnight .wrapper.-purple .button:focus & {
                background: var(--color-purple) !important;
            }

            .content-cards__arrow &, .jumbotron__arrow &, .image-list__arrow &, .content-card__info__summary & {
                mask-size: 1.2rem 1.2rem;
            }
            .content-cards__container.-purple .content-cards__arrow &, .content-card__info__summary &, .carrousel__footer &, .agora-header__info &, .agenda__footer & {
                background: var(--color-purple);
            }
        }
    }

    &.-arrow-left::before {
        transform: rotate(180deg);
    }

    &.-arrow-down::before,
    &.-arrow-up::before {
        width: 1.6rem;
        height: 1.5rem;

        background-image: url(arrow-down.svg);
        background-size: 1.6rem 1.5rem;

        .button.-general &, .filter__label &, .faq-item__button &, .page-header__link & {
            background-color: var(--color-purple);
        }

        .body.-abatnight & {
            background-color: var(--color-abatnight-body);
        }

        .button:hover &, .button:focus &, .page-header.-myab &, .body.-abatnight .filter__label & {
            background-color: var(--color-general);

            .event-detail__content.-general & {
                background-color: var(--color-general);
            }

            .body.-abatnight &,
            .event-detail__content.-abatnight &,
            .page-header.-abatnight & {
                background-color: var(--color-abatnight-white);
            }

            .body.-abatnight .button.-general & {
                background-color: var(--color-abatnight-body);
            }

            .event-detail__content.-family & {
                background-color: var(--color-family);
            }

            .event-detail__content.-fun & {
                background-color: var(--color-fun);
            }

            .event-detail__content.-rebellious & {
                background-color: var(--color-rebellious);
            }

            .event-detail__content.-well-informed & {
                background-color: var(--color-well-informed);
            }
        }

        .button & {
            background-color: var(--color-general);
        }

        @supports (mask-repeat: no-repeat) {
            mask-image: url(arrow-down.svg);
            mask-size: 1.6rem 1.5rem;

            .button.-general &, .filter__label &, .faq-item__button &, .page-header__link & {
                background: var(--color-purple);
            }

            .body.-abatnight &,
            .event-detail__content.-abatnight &,
            .page-header.-abatnight & {
                background: var(--color-abatnight-white);
            }

            .body.-abatnight .button.-general & {
                background: var(--color-abatnight-body);
            }

            .button:hover &, .button:focus &, .page-header.-myab & {
                background: var(--color-general);

                .event-detail__content.-general & {
                    background: var(--color-general);
                }

                .event-detail__content.-abatnight & {
                    background: var(--color-abatnight-white);
                }

                .event-detail__content.-family & {
                    background: var(--color-family);
                }

                .event-detail__content.-fun & {
                    background: var(--color-fun);
                }

                .event-detail__content.-rebellious & {
                    background: var(--color-rebellious);
                }

                .event-detail__content.-well-informed & {
                    background: var(--color-well-informed);
                }
            }

            .button & {
                background: var(--color-general);
            }
        }
    }

    &.-arrow-up::before {
        transform: rotate(180deg);
    }


    &.-hand:before {
        width: 1.9rem;
        height: 1.1rem;

        background-image: url(hand.svg);
        background-size: 1.9rem 1.1rem;

        background-color: var(--color-error);

        .-fun & {
            background-color: var(--color-fun);
        }

        .-family & {
            background-color: var(--color-family);
        }

        .-well-informed & {
            background-color: var(--color-well-informed);
        }

        .content-card .content-card__info__ticketlink &, .jumbotron__event__tickets &, .carrousel__content & {
            width: 4rem;
            height: 2.1rem;
            background-size: 4rem 2.1rem;

            background-color: var(--color-purple);

            .body.-abatnight &, .jumbotron__event.-abatnight & {
                background-color: var(--color-abatnight-white);
            }
        }

        .content-cards__container.-purple .content-card .content-card__info__ticketlink &, .carrousel__content & {
            background-color: var(--color-general);

            .body.-abatnight .wrapper.-purple & {
                background-color: var(--color-purple);
            }
        }

        .event-detail__ticket-button & {
            width: 3.3rem;
            height: 1.7rem;
            background-size: 3.3rem 1.7rem;

            background-color: var(--color-general);

            @media (--screen-full) {
                width: 4rem;
                height: 2.1rem;
                background-size: 4rem 2.1rem;
            }

            .event-detail__content.-family & {
                background-color: var(--color-family);
            }

            .event-detail__content.-fun & {
                background-color: var(--color-fun);
            }

            .event-detail__content.-rebellious & {
                background-color: var(--color-rebellious);
            }

            .event-detail__content.-well-informed & {
                background-color: var(--color-well-informed);
            }

            .event-detail__content.-abatnight & {
                background-color: var(--color-abatnight-white);
            }
        }

        .event-detail__ticket-button.-small &, .event-detail__ticket-button.-smaller & {
            background-size: 3.3rem 1.7rem;
        }

        @supports (mask-repeat: no-repeat) {
            mask-image: url(hand.svg);
            mask-size: 1.9rem 1.1rem;

            background: var(--color-error);

            .-fun & {
                background: var(--color-fun);
            }

            .-family & {
                background: var(--color-family);
            }

            .-well-informed & {
                background: var(--color-well-informed);
            }

            .content-card .content-card__info__ticketlink &, .jumbotron__event__tickets &, .carrousel__content & {
                background: var(--color-purple);
                mask-size: 4rem 2.1rem;

                .body.-abatnight & {
                    background: var(--color-abatnight-white);
                }
            }

            .content-cards__container.-purple .content-card .content-card__info__ticketlink &, .carrousel__content & {
                background: var(--color-general);

                .body.-abatnight .wrapper.-purple & {
                    background: var(--color-abatnight-body);
                }
            }

            .event-detail__ticket-button & {
                background: var(--color-general);
                mask-size: 3.3rem 1.7rem;

                @media (--screen-full) {
                    mask-size: 4rem 2.1rem;
                }

                .event-detail__content.-family & {
                    background: var(--color-family);
                }

                .event-detail__content.-fun & {
                    background: var(--color-fun);
                }

                .event-detail__content.-rebellious & {
                    background: var(--color-rebellious);
                }

                .event-detail__content.-well-informed & {
                    background: var(--color-well-informed);
                }

                .event-detail__content.-abatnight & {
                    background: var(--color-abatnight-white);
                }
            }

            .event-detail__ticket-button.-small &, .event-detail__ticket-button.-smaller & {
                mask-size: 3.3rem 1.7rem;
            }
        }
    }

    &.-heart::before {
        width: 2.6rem;
        height: 2.3rem;

        background-image: url(heart.svg);
        background-size: 2.6rem 2.3rem;

        .artist__favorite & {
            background-color: var(--color-white);

            @media (--screen-mini-medium) {
                width: 2.1rem;
                height: 1.8rem;

                background-size: 2.1rem 1.8rem;
            }
        }

        .artist__favorite.-favorite &, .artist__favorite:hover &, .artist__favorite:focus & {
            background-color: var(--color-general);
        }

        .body.-abatnight .filter__label & {
            background-color: var(--color-abatnight-white);
        }

        .filter__label & {
            width: 1.8rem;
            height: 1.6rem;
            background-color: var(--color-purple);
        }

        .filter__checkbox:checked + .filter__label & {
            background-color: var(--color-general);
        }

        @supports (mask-repeat: no-repeat) {
            mask-image: url(heart.svg);
            mask-size: 2.6rem 2.3rem;

            .artist__favorite & {
                background: var(--color-white);

                @media (--screen-mini-medium) {
                    mask-size: 2.1rem 1.8rem;
                }
            }

            .artist__favorite.-favorite &, .artist__favorite:hover &, .artist__favorite:focus & {
                background: var(--color-general);
            }

            .filter__label & {
                mask-size: 1.8rem 1.6rem;
                background: var(--color-purple);
            }

            .filter__checkbox:checked + .filter__label & {
                background: var(--color-general);
            }
        }
    }

    &.-win::before {
        width: 3.2rem;
        height: 3.2rem;

        background-image: url(win.svg);
        background-size: 3.2rem 3.2rem;

        @supports (mask-repeat: no-repeat) {
            mask-image: url(win.svg);
            mask-size: 3.2rem 3.2rem;
        }
    }

    &.-playlist::before {
        width: 1.8rem;
        height: 2.3rem;

        background-image: url(playlist.svg);
        background-size: 1.8rem 2.3rem;

        margin-left: -.4rem;

        .cta__list & {
            background-color: var(--color-general);
        }

        @supports (mask-repeat: no-repeat) {
            mask-image: url(playlist.svg);
            mask-size: 1.8rem 2.3rem;

            .cta__list & {
                background: var(--color-general);
            }
        }
    }

    &.-abtv::before {
        width: 1.6rem;
        height: 1.9rem;
        transform: translateX(-25%);

        background-image: url(abtv.svg);
        background-size: 1.6rem 1.9rem;

        .video__cover &, .event-video__cover & {
            width: 4rem;
            height: 4rem;
            transform: none;

            background-size: 4rem 4rem;

            @media (--screen-full) {
                width: 5.8rem;
                height: 5.8rem;

                background-size: 5.8rem 5.8rem;
            }

            .event-detail__content.-family & {
                background-color: var(--color-family);
            }

            .event-detail__content.-fun & {
                background-color: var(--color-fun);
            }

            .event-detail__content.-rebellious & {
                background-color: var(--color-rebellious);
            }

            .event-detail__content.-well-informed & {
                background-color: var(--color-well-informed);
            }
        }

        @supports (mask-repeat: no-repeat) {
            mask-image: url(abtv.svg);
            mask-size: 1.6rem 1.9rem;

            .video__cover &, .event-video__cover & {
                mask-size: 4rem 4rem;

                @media (--screen-full) {
                    mask-size: 5.8rem 5.8rem;
                }

                .event-detail__content.-family & {
                    background: var(--color-family);
                }

                .event-detail__content.-fun & {
                    background: var(--color-fun);
                }

                .event-detail__content.-rebellious & {
                    background: var(--color-rebellious);
                }

                .event-detail__content.-well-informed & {
                    background: var(--color-well-informed);
                }
            }
        }
    }

    &.-audio::before {
        width: 2rem;
        height: 1.8rem;

        background-image: url(audio.svg);
        background-size: 2rem 1.8rem;

        .audio-player & {
            background-color: var(--color-purple);

            .body.-abatnight & {
                background-color: var(--color-black);
            }
        }

        .event-detail__header .audio-player & {
            background-color: var(--color-general);

            .body.-abatnight & {
                background-color: var(--color-black);
            }
        }

        .audio-player:hover &, .audio-player:focus & {
            background-color: var(--color-general);

            .body.-abatnight & {
                background-color: var(--color-abatnight-white);
            }
        }

        .event-detail__header.-fun .audio-player & {
            background-color: var(--color-fun);
        }

        @supports (mask-repeat: no-repeat) {
            mask-image: url(audio.svg);
            mask-size: 2rem 1.8rem;

            .audio-player & {
                background: var(--color-purple);
            }

            .audio-player:hover &, .audio-player:focus &, .event-detail__header .audio-player & {
                background: var(--color-general);
            }

            .event-detail__header.-family .audio-player & {
                background: var(--color-family);
            }

            .event-detail__header.-fun .audio-player & {
                background: var(--color-fun);
            }

            .event-detail__header.-rebellious .audio-player & {
                background: var(--color-rebellious);
            }

            .event-detail__header.-well-informed .audio-player & {
                background: var(--color-well-informed);
            }
        }
    }

    &.-location::before {
        width: 1.8rem;
        height: 2.4rem;

        background-image: url(location.svg);
        background-size: 1.8rem 2.4rem;

        @supports (mask-repeat: no-repeat) {
            mask-image: url(location.svg);
            mask-size: 1.8rem 2.4rem;
        }
    }

    &.-download::before {
        width: 1.7rem;
        height: 1.7rem;

        background-image: url(download.svg);
        background-size: 1.7rem 1.7rem;

        .button & .button:hover &, .button:focus & {
            background-color: var(--color-general) !important;
        }

        @supports (mask-repeat: no-repeat) {
            mask-image: url(download.svg);
            mask-size: 1.7rem 1.7rem;

            .button & .button:hover &, .button:focus & {
                background: var(--color-general) !important;
            }
        }
    }

    &.-select::before {
        width: 2.1rem;
        height: 1.2rem;

        background-image: url(select.svg);
        background-size: 2.1rem 1.2rem;

        @supports (mask-repeat: no-repeat) {
            mask-image: url(select.svg);
            mask-size: 2.1rem 1.2rem;
        }
    }

    &.-ticketswap::before {
        width: 2rem;
        height: 1.3rem;

        background-image: url(ticketswap.svg);
        background-size: 2rem 1.3rem;

        @supports (mask-repeat: no-repeat) {
            mask-image: url(ticketswap.svg);
            mask-size: 2rem 1.3rem;
        }
    }

    &.-list::before {
        width: 2.1rem;
        height: 1.6rem;

        background-image: url(list.svg);
        background-size: 2.1rem 1.6rem;

        @supports (mask-repeat: no-repeat) {
            mask-image: url(list.svg);
            mask-size: 2.1rem 1.6rem;
        }
    }

    &.-shuffle::before {
        width: 2.1rem;
        height: 2.1rem;

        background-image: url(shuffle.svg);
        background-size: 2.1rem 2.1rem;

        .button & {
            background-color: var(--color-general);
        }

        .button.-general & {
            background-color: var(--color-purple);
        }

        @supports (mask-repeat: no-repeat) {
            mask-image: url(shuffle.svg);
            mask-size: 2.1rem 2.1rem;

            .button & {
                background: var(--color-general);
            }

            .button.-general & {
                background: var(--color-purple);
            }
        }
    }

    &.-sort::before {
        width: 1.6rem;
        height: 1.5rem;

        background-image: url(sort.svg);
        background-size: 1.6rem 1.5rem;

        .button.-general &, .filter__label &, .faq-item__button &, .page-header__link & {
            background-color: var(--color-purple);
        }

        .button:hover &, .button:focus &, .page-header.-myab & {
            background-color: var(--color-general);
        }

        .body.-abatnight .filter__label & {
            background-color: var(--color-abatnight-white);
        }

        .button & {
            background-color: var(--color-general);
        }

        .filter__checkbox:checked + .filter__label & {
            background-color: var(--color-general);
        }

        @supports (mask-repeat: no-repeat) {
            mask-image: url(sort.svg);
            mask-size: 1.6rem 1.5rem;

            .button.-general &, .filter__label &, .faq-item__button &, .page-header__link & {
                background: var(--color-purple);
            }

            .button:hover &, .button:focus &, .page-header.-myab & {
                background: var(--color-general);
            }

            .button & {
                background: var(--color-general);
            }

            .filter__checkbox:checked + .filter__label & {
                background: var(--color-general);
            }
        }
    }
}
