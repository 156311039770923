.other__container {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    margin-bottom: 3rem;

    .page-detail__content__info &, .event-detail__content & {
        max-width: 75rem;
        margin: 0 auto 3rem;
    }

    iframe {
        max-width: 100% !important;
    }
}
