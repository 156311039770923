.newsletter__container {
    background: var(--color-general);
    padding: 11rem 0;

    background-size: 285%;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    @media (--screen-small) {
        background-size: 230%;
    }

    @media (--screen-medium) {
        background-size: 185%;
    }

    @media (--screen-large) {
        background-size: 140%;
    }

    @media (--screen-full) {
        background-size: cover;
    }

    @media (--screen-medium-large) {
        padding: 14rem 0;
    }

    &.-rebellious {
        background-color: var(--color-rebellious);
        background-image: url('rebellious.svg');
    }

    &.-fun {
        background-color: var(--color-fun);
        background-image: url('fun.svg');
    }

    &.-family {
        background-color: var(--color-family);
        background-image: url('family.svg');
    }

    &.-well-informed {
        background-color: var(--color-well-informed);
        background-image: url('well-informed.svg');
    }
}

.newsletter {
    max-width: 26rem;
    margin: 0 auto;
    text-align: center;
    font-size: 3.2rem;
    font-family: var(--font-general);
    color: var(--color-purple);
    line-height: 1.15;

    @media (--screen-small) {
        max-width: 32rem;
    }

    @media (--screen-medium-large) {
        max-width: 32rem;
        font-size: 3.5rem;
    }

    @media (--screen-full) {
        max-width: 40rem;
    }
}

.newsletter__input {
    position: relative;
    margin: 1.5rem auto 1rem;

    input {
        padding-right: 5rem;
    }
}

.newsletter__button {
    position: absolute;
    top: 1.2rem;
    right: 0;
    width: 3rem;
    height: 3rem;
    border: .1rem solid var(--color-purple);
    background-color: var(--color-purple);
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    transition: all .3s ease;

    &::before {
        background-color: var(--color-general) !important;
        width: 1.3rem !important;
        height: 1.3rem !important;
        background-size: 1.3rem 1.3rem !important;

        @supports (mask-repeat: no-repeat) {
            mask-size: 1.3rem 1.3rem !important;
        }
    }

    &:hover,
    &:focus {
        background-color: var(--color-general);
        border-color: var(--color-general);

        &::before {
            background: var(--color-purple) !important;
        }
    }
}

.newsletter__info {
    display: block;
    padding-top: 2rem;
    font-size: 1.4rem;
    color: var(--color-purple);

    a {
        color: var(--color-purple);
        text-decoration: underline;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}
