.team {
    .page-detail__content & {
        margin: 0 auto 4rem auto;
    }
}

.team__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 3rem;
    gap: 3rem;
    row-gap: 3rem;
    list-style-type: none;
    width: 100%;
    padding: 3rem 0;
    border-bottom: 0.1rem dotted var(--color-border-dark);

    @media (--screen-large) {
        border-bottom: 0;
        border-top: 0.1rem dotted var(--color-border-dark);
    }
}

.team__list__item {
    flex-basis: 40rem;

    @media (--screen-large) {
        flex-basis: calc(50% - 1.5rem);
    }

    @media (--screen-jumbotron) {
        flex-basis: calc((100%/3) - 2rem);
    }
}

.team__member {
    display: block;
    text-decoration: none;
    color: var(--color-purple);
    font-weight: var(--font-weight-regular);
    font-size: 2.8rem;
    font-family: var(--font-general);
    line-height: 1.15;
    box-sizing: border-box;
    padding: 1.6rem;
    width: 100%;
    max-width: 40rem;
    transition: all .3s ease;

    img {
        margin: 0 -1.6rem 1.6rem;
        max-width: calc(100% + 3.2rem);
        transition: all .3s ease;
        min-height: 18rem;
        height: auto;
    }
}

.team__member__email {
    display: inline-block;
    min-height: auto !important;
    margin: 0 0 0 .5rem !important;
    position: relative;
    top: .3rem;
    width: 2rem;
    height: 2rem;
    background-size: 2rem 2rem;
    background-image: url(../icon/email.svg);
    transition: all .3s ease;

    @supports (mask-repeat: no-repeat) {
        background: var(--color-purple);
        mask-image: url(../icon/email.svg);
        mask-size: 2rem 2rem;
    };
}

.team__member__function {
    color: var(--color-purple);
    font-weight: var(--font-weight-regular);
    font-size: 1.8rem;
    font-family: var(--font-general);
    display: block;
    margin-bottom: 1rem;
    transition: all .3s ease;
}

.team__member__name {
    display: block;
    margin-bottom: 2rem;
}

.team__category {
    display: block;
    margin: 0 0 1.6rem;
    font-size: 3.5rem;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    color: var(--color-purple);
    line-height: 1.15;
    text-decoration: none;

    @media (--screen-large) {
        font-size: 4.3rem;
    }
}

.team__category__trigger {
    display: none;
}

.team__category__content {
    max-height: 0;
    overflow: hidden;
    transition: max-height .3s ease;
    margin-bottom: 1rem;

    .team__category__trigger:checked ~ & {
        max-height: none;
    }

    @media (--screen-large) {
        max-height: none;
    }
}

.team__category__question {
    display: block;
    cursor: pointer;
    transition: opacity .3s ease;
    padding-left: 5rem;
    position: relative;

    &:hover, &:focus {
        opacity: .6;

        @media (--screen-large) {
            opacity: 1;
        }
    }

    @media (--screen-large) {
        padding-left: 0;
        cursor: default;
    }
}

.team__category__button {
    background: transparent;
    border: 0;
    border-radius: 50%;
    display: inline-block;
    width: 4rem;
    height: 4rem;
    position: absolute;
    top: .4rem;
    left: 0;
    outline: none;
    transition: all .3s ease;
    text-align: center;
    font: 0/4rem sans-serif;

    .team__category__trigger:checked + .team__category .team__category__question & {
        transform: rotate(180deg);
    }

    @media (--screen-large) {
        display: none;
    }

}
