.quote {
    margin-block: 3rem !important;
}

.quote__quote {
    margin: 0;

    font-size: 2.5rem;
    font-weight: var(--font-weight-medium);
    line-height: 1.3;
    text-align: center;

    &::before {
        content: '\201C';
    }

    &::after {
        content: '\201D';
    }
}

.quote__author {
    margin-block-start: 2rem;
    font-size: 1.3rem;
    line-height: 1.2;
    text-align: center;
    color: var(--color-title);
}

@media (--screen-from-md) {
    .quote__quote {
        font-size: 4rem;
    }

    .quote__author {
        font-size: 1.5rem;
    }
}
