.file-element {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: .4rem 1rem;

    border-bottom: .1rem solid var(--color-faq);
}

.file-element__data {
    margin: 0;
}

.file-element__name {
    margin: 0;
    font-size: 2.5rem;
    font-weight: var(--font-weight-normal);
    line-height: 1.44;
    letter-spacing: .04rem;
}

.file-element__meta {
    display: block;
    font-size: 1.2rem;
    line-height: 1.5;
    color: var(--color-title);

    span {
        margin-inline-end: 1rem;

        &:last-child {
            margin-inline-end: 0;
        }
    }
}

.file-element__file {
    margin-inline-start: 2rem;

    .icon {
        margin: 0 1rem 0 0;
    }
}
