.title-1, .title-2, .title-3, .title-4, .title-5, .title-6 {
    margin-block: .75rem 1rem;
    font-weight: var(--font-weight-medium);
    line-height: 1.3;
    color: var(--color-purple);

    strong, [data-block-type="form"] & {
        font-weight: var(--font-weight-medium);
    }
}

.title-1 {
    font-size: 4.6rem;
}

.title-2 {
    font-size: 2.8rem;
    font-weight: var(--font-weight-regular);
}

.title-3 {
    font-size: 2.3rem;
}

.title-4 {
    font-size: 1.8rem;
}

.title-5 {
    font-size: 1.6rem;
}

@media (--screen-from-sm) {
    .title-1 {
        font-size: 5rem;
    }

    .title-2 {
        font-size: 3rem;
    }

    .title-3 {
        font-size: 2.5rem;
    }

    .title-4 {
        font-size: 2rem;
    }

    .title-5 {
        font-size: 1.8rem;
    }
}
