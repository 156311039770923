@use 'figure';

figure.table {
    margin-block: 1.5rem 3rem;
}

:where(table:not([class])), .table {
    overflow: hidden;
    table-layout: fixed;
    border-collapse: collapse;

    inline-size: 100%;
    margin-block: 1rem 3.2rem;

    font-size: 1.4rem;
    line-height: 1.7;
    color: var(--color-purple);

    border-radius: .4rem .4rem 0 0;

    td, th {
        padding: .8rem 1.6rem;
        vertical-align: top;
        border: .1rem solid var(--color-border);
    }

    th {
        font-size: 1.1rem;
        font-weight: var(--font-weight-medium);
        line-height: 1.6;
        color: var(--color-purple-hover);
        text-align: start;
        text-transform: uppercase;

        background-color: var(--color-general);
    }
}

@media (--screen-from-sm) {
    figure.table {
        margin-block: 2rem 4rem;
    }
}

@media (--screen-from-xxl) {
    figure.table {
        margin-block: 3rem 6rem;
    }
}
