.image-grid {
    margin: 2rem auto !important;
    max-width: 100rem;
}

.image-grid__grid {
    display: block;
    gap: 2rem;
    column-count: 1;

    [data-columns='two'] &, [data-columns='three'] &, [data-columns='four'] & {
        column-count: 2;
    }

    .image-element {
        margin-bottom: 2rem;
    }
}

.glightbox {
    cursor: pointer;
    transition: opacity .3s ease;

    &:hover, &:focus {
        opacity: .75;
    }
}

@media (--screen-from-md) {
    .image-grid {
        margin-block: 2rem 3rem;
    }

    .image-grid__grid {
        gap: 3rem;

        .image-element {
            margin-bottom: 3rem;
        }

        [data-columns='three'] & {
            column-count: 3;
        }

        [data-columns='four'] & {
            column-count: 4;
        }
    }
}
