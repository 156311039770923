hr {
    position: relative;
    margin-block: 5rem !important;
    padding-block: 1rem;
    border: none;

    &::after {
        content: '';

        position: absolute;
        inset-block-start: 50%;
        inset-inline: 0;
        transform: translateY(-50%);

        display: block;

        inline-size: 100%;
        block-size: .1rem;

        background-color: var(--color-title);
    }
}
