.image-full {
    inline-size: 100%;
    margin-block: 2rem 6rem;

    img, .text & img {
        max-height: 100%;
    }

    figcaption {
        padding-inline: 1rem;
    }
}
