.image-with-text {
    margin: 1.5rem 0 2.5rem;
}

.image-with-text__text {
    font-size: 2.2rem;

    :first-child {
        margin-block-start: 0;
    }

    :last-child {
        margin-block-end: 0;
    }
}

.image-with-text__image {
    margin-block-end: 3.7rem;
}

@media (--screen-from-sm) {
    .image-with-text {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.-reverse, [data-order='text-image'] & {
            .image-with-text__image {
                order: 1;
            }

            .image-with-text__text {
                order: 0;
            }
        }
    }

    .image-with-text__image, .image-with-text__text {
        flex: 0 0 calc(50% - 1.75rem);
        margin: 0;
    }
}

@media (--screen-from-lg) {
    .image-with-text {
        align-items: center;
    }

    .image-with-text__image, .image-with-text__text {
        flex: 0 0 calc(50% - 2rem);
    }
}
