ul:not([class]) {
    margin: 2rem 2rem !important;
    padding: 0;

    li {
        padding: 0 0 1rem;
        position: relative;
        font-size: 1.8rem;
        font-family: var(--font-general);
        font-weight: var(--font-weight-regular);
        color: var(--color-purple);

        ul {
            margin-top: 1rem !important;
        }
    }
}

ol:not([class]) {
    margin: 2rem 0 !important;
    padding: 0;
    list-style: none;
    counter-reset: section;

    li {
        padding: 1rem 0 1rem 3rem;
        position: relative;
        font-size: 1.8rem;
        font-family: var(--font-general);
        font-weight: var(--font-weight-regular);
        color: var(--color-purple);
        counter-increment: step-counter;
        min-height: 3rem;
        border-top: .1rem solid var(--color-purple);

        &::before {
            content: counter(step-counter);
            display: block;
            width: 3rem;
            height: 3rem;
            position: absolute;
            top: .9rem;
            left: 0;
            line-height: 3rem;
            text-align: center;
            border-radius: 50%;
            font-size: 1.8rem;
            font-family: var(--font-general);
            font-weight: var(--font-weight-regular);
            text-align: left;

            .event-detail__content.-family & {
                background-color: var(--color-family);
            }

            .event-detail__content.-fun & {
                background-color: var(--color-fun);
            }

            .event-detail__content.-rebellious & {
                background-color: var(--color-rebellious);
            }

            .event-detail__content.-well-informed & {
                background-color: var(--color-well-informed);
            }
        }

        ul {
            margin-top: 1rem !important;
        }
    }
}
