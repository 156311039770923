.video_pagebuilder {
    position: relative;

    inline-size: 100%;
    block-size: 0;
    margin-block: 1rem 2rem;
    padding-block-end: calc(100% * (9 / 16));
}

.video_pagebuilder__frame {
    position: absolute;
    inset: 0;

    inline-size: 100%;
    block-size: 100%;

    background-color: var(--color-general);
    background-image: url('../../images/video-placeholder.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 5.6rem;
}

@media (--screen-from-md) {
    .video_pagebuilder {
        margin-block: 2rem 4rem;
    }
}

@media (--screen-from-lg) {
    .video_pagebuilder {
        margin-block: 3rem 6rem;
    }
}
