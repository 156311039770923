.button, .button a {
    display: inline-block;
    background: var(--color-purple);
    border: 0;
    color: var(--color-general) !important;
    font-size: 1.8rem;
    line-height: 1.15;
    font-family: var(--font-general) !important;
    font-weight: var(--font-weight-regular) !important;
    border-radius: 3rem;
    padding: 1rem 1.5rem !important;
    text-decoration: none !important;
    transition: all .3s ease;
    cursor: pointer;
    text-align: center;
    box-sizing: border-box;
    outline: none;

    &:hover, &:focus,
    .info-card a:hover &, .info-card a:focus &,
    .cta__container a.cta:hover &, .cta__container a.cta:focus & {
        background: var(--color-purple-hover);
        color: var(--color-general) !important;

        .icon:not(.-arrow-down):not(.-arrow-up):not(.-download) {
            transform: translateX(.5rem);
        }

        body.-abatnight & {
            background: var(--color-abatnight);
            color: var(--color-abatnight-white) !important;
        }
    }

    body.-abatnight & {
        color: var(--color-abatnight-white);
    }

    &:disabled,
    &[disabled] {
        opacity: .4;
        pointer-events: none;
    }

    &.-general {
        background: var(--color-general);
        color: var(--color-purple) !important;

        .event-detail__content.-family & {
            background: var(--color-family);
        }

        .event-detail__content.-fun & {
            background: var(--color-fun);
        }

        .event-detail__content.-rebellious & {
            background: var(--color-rebellious);
        }

        .event-detail__content.-well-informed & {
            background: var(--color-well-informed);
        }

        .event-detail__content.-abatnight & {
            background: var(--color-abatnight-bg);
            color: var(--color-abatnight-body) !important;
        }

        .body.-abatnight .wrapper.-purple & {
            background: var(--color-black);
            color: var(--color-abatnight-white) !important;
        }

        &:hover, &:focus,
        .content-card:not(.-concert):hover &, .content-card:not(.-concert):focus & {
            background: var(--color-general-hover);
            color: var(--color-purple) !important;

            .event-detail__content & {
                background: var(--color-purple);
                color: var(--color-general) !important;
            }

            .event-detail__content.-family & {
                color: var(--color-family) !important;
            }

            .event-detail__content.-fun & {
                color: var(--color-fun) !important;
            }

            .event-detail__content.-rebellious & {
                color: var(--color-rebellious) !important;
            }

            .event-detail__content.-well-informed & {
                color: var(--color-well-informed) !important;
            }

            .body.-abatnight .wrapper.-purple & {
                background: var(--color-abatnight-white);
                color: var(--color-purple) !important;
            }

            .icon:not(.-arrow-down):not(.-arrow-up):not(.-download) {
                transform: translateX(.5rem);
            }
        }
    }

    &[data-theme='general'] {
        background: var(--color-general);
        color: var(--color-purple) !important;

        &:hover, &:focus {
            background: var(--color-general-hover);
            color: var(--color-purple) !important;
        }
    }

    &[data-theme='family'] {
        background: var(--color-family);
        color: var(--color-purple) !important;

        &[data-style='outline'] {
            background-color: transparent;
            border-color: var(--color-family);
            color: var(--color-family) !important;
        }

        &:hover, &:focus {
            background: var(--color-family-bg);
            color: var(--color-purplefamily) !important;

            &[data-style='outline'] {
                background-color: var(--color-family);
                color: var(--color-purple) !important;
            }
        }
    }

    &[data-theme='fun'] {
        background: var(--color-fun);
        color: var(--color-purple) !important;

        &[data-style='outline'] {
            background-color: transparent;
            border-color: var(--color-fun);
            color: var(--color-purple) !important;
        }

        &:hover, &:focus {
            background: var(--color-fun-bg);
            color: var(--color-purple) !important;

            &[data-style='outline'] {
                background-color: var(--color-fun);
                color: var(--color-purple) !important;
            }
        }
    }

    &[data-theme='rebellious'] {
        background: var(--color-rebellious);
        color: var(--color-purple) !important;

        &[data-style='outline'] {
            background-color: transparent;
            border-color: var(--color-rebellious);
            color: var(--color-rebellious) !important;
        }

        &:hover, &:focus {
            background: var(--color-rebellious-bg);
            color: var(--color-purple) !important;

            &[data-style='outline'] {
                background-color: var(--color-rebellious);
                color: var(--color-general) !important;
            }
        }
    }

    &[data-theme='well-informed'] {
        background: var(--color-well-informed);
        color: var(--color-purple) !important;

        &[data-style='outline'] {
            border-color: var(--color-well-informed);
            color: var(--color-purple) !important;
        }

        &:hover, &:focus {
            background: var(--color-well-informed-bg);
            color: var(--color-purple) !important;

            &[data-style='outline'] {
                background-color: var(--color-well-informed);
                color: var(--color-purple) !important;
            }
        }
    }

    [data-block-type='button-row'][data-theme] & {
        box-shadow: 0 .5rem .5rem 0 var(--color-purple-hover);
    }

    [data-block-type='button-row'][data-theme='general'] & {
        box-shadow: none;
    }

    &.-full, &[data-size='large'] {
        display: block;
        width: 100%;
    }

    &.-outline, &[data-style='outline'] {
        background-color: transparent;
        border: .1rem solid var(--color-purple);
        color: var(--color-purple) !important;

        &:hover, &:focus {
            background-color: var(--color-purple);
            color: var(--color-general) !important;
        }
    }

    .icon {
        margin-left: 1rem;
        transition: all .3s ease;
    }

    &.-square {
        border-radius: 0;
        padding: .8rem 1.2rem;
        line-height: 1.2;
        font-size: 1.7rem;
    }

    &.-social {
        background-color: transparent;
        border: .2rem solid var(--color-purple);
        color: var(--color-purple) !important;
        padding: 1.5rem 8rem !important;
        text-align: left;
        position: relative;
        margin-bottom: 1rem;
        width: 100%;
        border-radius: 10rem;
        font-size: 1.6rem;
        line-height: 1.3;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 1.5rem;
            left: 1.5rem;
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
            background: var(--color-purple);
        }

        &.-facebook{
            &::before {
                background: var(--color-facebook);
            }
        }

        &.-spotify{
            &::before {
                background: none;
            }
        }

        small {
            display: block;
            color: #AB94A4 !important;
            font-size: 1.6rem;
            padding-top: .5rem;
        }

        &:hover, &:focus {
            background-color: var(--color-purple-hover);
            color: var(--color-general) !important;
        }

        .button__status {
            position: absolute;
            top: 50%;
            right: 2rem;
            transform: translateY(-50%);
            inline-size: unset;
            block-size: unset;
        }

        .button__icon {
            position: absolute;
            top: 1.5rem;
            left: 1.5rem;
            width: 4rem;
            height: 4rem;
            margin: 0;
            text-align: center;
            line-height: 4rem;
        }
    }

    .body.-abatnight .info-card &, .body.-abatnight & {
        box-shadow: 0 0 1rem 0 var(--color-abatnight);
        background: var(--color-abatnight-body);
        color: var(--color-general);

        &:hover, &:focus {
            background: var(--color-abatnight);
        }
    }

    &.-toggle {
        width: 8rem;
        height: 4rem;
        font-size: 0;
        background-color: var(--color-title);
        position: relative;
        cursor: pointer;

        span {
            position: absolute;
            top: .5rem;
            left: .5rem;
            display: block;
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            background-color: var(--color-white);
        }

        &.-checked {
            background-color: var(--color-purple);

            span {
                left: auto;
                right: .5rem;
            }
        }

        &.-loading {
            pointer-events: none;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjAiIHdpZHRoPSI2NHB4IiBoZWlnaHQ9IjY0cHgiIHZpZXdCb3g9IjAgMCAxMjggMTI4IiB4bWw6c3BhY2U9InByZXNlcnZlIj48Zz48cGF0aCBmaWxsPSIjMzAwMDI5IiBkPSJNMTA5LjI1IDU1LjVoLTM2bDEyLTEyYTI5LjU0IDI5LjU0IDAgMCAwLTQ5LjUzIDEySDE4Ljc1QTQ2LjA0IDQ2LjA0IDAgMCAxIDk2LjkgMzEuODRsMTIuMzUtMTIuMzR2MzZ6bS05MC41IDE3aDM2bC0xMiAxMmEyOS41NCAyOS41NCAwIDAgMCA0OS41My0xMmgxNi45N0E0Ni4wNCA0Ni4wNCAwIDAgMSAzMS4xIDk2LjE2TDE4Ljc0IDEwOC41di0zNnoiLz48YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InJvdGF0ZSIgZnJvbT0iMCA2NCA2NCIgdG89IjM2MCA2NCA2NCIgZHVyPSIxMjgwbXMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIj48L2FuaW1hdGVUcmFuc2Zvcm0+PC9nPjwvc3ZnPg==');
                background-repeat: no-repeat;
                background-size: 3rem;
                background-position: center;
                background-color: var(--color-white);
                opacity: .7;
            }
        }
    }

    &:has(> span) {
        padding: 0;
        > span {
            display: inline-block;
            padding: .3em 1.2rem;
        }
    }
}

div.button {
    padding: 0 !important;
    background: none;
    color: var(--color-purple) !important;
    margin: 1rem 0 3.2rem;

    p + & {
        margin-top: 0;
    }

    + p {
        margin-top: 0;
    }
}
