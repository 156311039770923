.myab__cta {
    background-color: var(--color-rebellious);
    background-size: 200%;
    background-repeat: no-repeat;
    background-position: 52% 50%;
    padding: 4rem 0 0;

    @media (--screen-full) {
        background-size: cover;
        background-image: url('rebellious.svg');
        padding: 11rem 0;
    }
}

.myab__cta__content {
    margin: 0 auto;
    text-align: center;
    font-size: 1.8rem;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    color: var(--color-purple);
    line-height: 1.5;

    @media (--screen-full) {
        max-width: 50rem;
    }

    .button {
        margin-top: 2rem;
    }

    p {
        padding: 0 var(--side-padding);
        margin-top: 0;

        @media (--screen-full) {
            padding: 0;
        }
    }
}

.myab__cta__title {
    font-size: 2.6rem;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    color: var(--color-purple);
    line-height: 1.15;
    padding: 0 var(--side-padding) 4rem;
    display: block;

    @media (--screen-full) {
        font-size: 3.2rem;
        padding: 0 0 1.5rem;
    }
}

.myab__cta__button {
    padding: 5rem 2rem;
    background: url('../images/rebellious.svg') no-repeat 45% 55%;
    background-size: 250rem 250rem;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    overflow: hidden;

    @media (--screen-full) {
        background: none;
        margin-left: auto;
        width: auto;
        padding: 2rem 4rem 0;
    }

    .button {
        margin: 0;
    }
}
