.image-with-text-alt {
    margin-block: 1rem;

    [data-size='large'] & {
        background-color: var(--color-purple);
    }
}

.image-with-text-alt__image {
    box-sizing: border-box;
    position: relative;

    [data-size='small'] & {
        padding: 7.5%;

        figcaption {
            color: var(--color-purple);
        }
    }

    [data-size='large'] & {
        figcaption {
            position: absolute;
            bottom: 0;
            right: 0;
            padding: .5rem;
            background-color: var(--color-purple);
            color: var(--color-general);
        }
    }
}

.image-with-text-alt__content {
    box-sizing: border-box;
    padding: 2rem;

    [data-size='small'] & {
        padding: 7.5%;
    }
}

.image-with-text-alt__content-wrapper {
    max-inline-size: 64rem;
    margin: auto;

    [data-size='large'] & {
        color: var(--color-general);
    }
}

.image-with-text-alt__tag {
    display: block;
    margin: 0 0 2.2rem;
    font-size: 1.6rem;
    font-weight: var(--font-weight-normal);
}

.image-with-text-alt__title {
    margin: 0 0 2.2rem;
    font-size: 3.6rem;
    line-height: 1.44;

    [data-size='large'] & {
        color: var(--color-white);
    }
}

.image-with-text-alt__description {
    margin: 0 0 6rem;
    font-size: 2rem;
    line-height: 1.7;
}

.image-with-text-alt__button {
    [data-size='large'] & {
        background-color: var(--color-general);
        color: var(--color-purple) !important;

        &:--focus {
            background-color: var(--color-general-hover);
        }
    }
}

@media (--screen-from-md) {
    .image-with-text-alt {
        display: flex;
        align-items: center;
    }

    .image-with-text-alt__image {
        flex: 0 0 50%;
        justify-self: stretch;

        img {
            display: block;
            inline-size: 100%;
            block-size: 100%;
            object-fit: cover;
        }

        [data-order='text-first'] & {
            order: 1;

            figcaption {
                left: 0;
                right: auto;
            }
        }
    }

    .image-with-text-alt__content {
        flex: 0 0 50%;

        [data-order='text-first'] & {
            order: 0;
        }
    }
}
