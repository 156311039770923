.page-nav {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -9;
    width: 100%;
    height: 100%;
    min-height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 0 0 2rem;

    @media (--screen-full) {
        clip-path: circle(0% at 0 0);
        transition: clip-path 1s ease, z-index 4s;
    }

    .site-container {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &.-active {
        background-color: var(--color-purple);
        overflow-y: auto;
        z-index: 9;

        @media (--screen-full) {
            clip-path: circle(200% at 0% 0%);
            transition: clip-path 1s ease;
        }
    }

    &::before, &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: var(--color-purple);
        z-index: -2;
    }
}

.page-nav__header {
    padding-bottom: 1rem;
    display: flex;
    height: 7rem;
    align-items: center;

    @media (--screen-large) {
        padding-bottom: 5rem;
    }
}

.page-nav__header, .page-nav__footer {
    flex-shrink: 0;
}

.page-nav__center {
    flex: 1 0 auto;
    padding-bottom: 6rem;

    @media (--screen-large) {
        display: flex;
    }
}

.page-nav__close.icon {
    padding: 1.4rem 0;
    display: inline-flex;
    margin: 0 6rem 0 0;
    border: 0;
    font-size: 1.8rem;
    color: var(--color-general);
    font-family: var(--font-general);
    cursor: pointer;
    transition: opacity .3s ease;
    outline: none;
    background-color: var(--color-purple);

    &::before {
        background: var(--color-general) !important;
        margin-right: 1.2rem;
    }

    &:hover,
    &:focus {
        opacity: .8;

        &::before {
            background: var(--color-general) !important;
        }
    }
}

.page-nav__language__container {
    margin: 0 1.6rem 0 auto;
    display: inline-block;
    position: relative;
    width: 6rem;
    height: 3rem;
}

.page-nav__language {
    position: absolute;
    margin: .2rem .1rem;
    padding: 0;
    list-style: none;
    display: inline-flex;
    flex-direction: column;
    text-align: left;
    text-transform: uppercase;
    background: var(--color-purple);

    .page-nav__footer & {
        display: none;
        left: 2rem;
        margin: 1rem 0 0 0;
    }

    @media (--screen-mini-small) {
        .page-nav__footer & {
            display: block;
        }
    }

    &:hover,
    &:focus {
        li {
            display: block;
            margin-bottom: 1rem;
        }
    }

    li {
        display: none;

        &.-active {
            order: -1;
            display: block;
            pointer-events: none;

            .icon::before {
                display: block;
            }
        }
    }

    li.-hidden {
        display: none;
    }
}

.page-nav__language__item {
    color: var(--color-general);
    text-decoration: none;

    .icon::before {
        background: var(--color-general) !important;
        display: none;
        margin-left: .4rem;
        margin-top: -.1rem;
    }

    &:hover,
    &:focus {
        opacity: .6;
    }
}

.page__nav-list {
    margin: 0 0 4rem 0;
    padding: 0;
    list-style: none;

    @media (--screen-large) {
        margin: 0 20rem 4rem 0;
    }

    li {
        padding: 1.5rem 0;

        @media (--screen-medium-large) {
            padding: 2rem 0;
        }
    }

    &.-sub {
        margin: 1rem 0 3rem;

        @media (--screen-full) {
            margin: 3rem 0;
        }
    }
}

.page__nav-item {
    font-family: var(--font-general);
    font-size: 3.7rem;
    font-weight: var(--font-weight-regular);
    color: var(--color-general);
    text-decoration: none;
    transition: opacity .3s ease, color .3s ease;
    line-height: 1.2;

    @media (--screen-full) {
        font-size: 7rem;
    }

    &:hover,
    &:focus {
        opacity: .6;
    }

    .page__nav-list.-sub & {
        color: var(--color-submenu);
        font-size: 2.3rem;

        @media (--screen-full) {
            font-size: 3.2rem;
        }

        &:hover,
        &:focus {
            opacity: 1;
            color: var(--color-white);
        }
    }
}

.page-nav__footer {
    text-align: right;
    align-self: flex-end;
    width: 100%;

    .liveurope {
        opacity: .6;

        &:hover,
        &:focus {
            opacity: 1;
        }
    }

    @media (--screen-medium-large) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: left;
    }
}

.page-nav__socials {
    border: dashed var(--color-border);
    padding: 2.5rem 0;
    margin-bottom: 3.5rem;
    border-width: .1rem 0;
    text-align: center;

    @media (--screen-medium-large) {
        border: 0;
        padding: 0;
        margin: 0;
        text-align: left;
    }
}

.page-nav__social-item {
    transition: opacity .3s ease;
    opacity: .6;
    margin: 0 3rem 0 0;

    &::before {
        background: var(--color-general) !important;
    }

    &:hover,
    &:focus {
        opacity: 1;

        &::before {
            background: var(--color-general) !important;
        }
    }

    &:last-child {
        margin-right: 0;
    }
}

.page-nav__search {
    padding: 1rem 0;
    position: relative;
    top: .1rem;
}
