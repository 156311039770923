.series {
    display: block;
    margin: 0 auto;

    width: 100%;
    height: 100%;
}

.series__masthead {
    text-align: center;
    padding: 1.44rem;
    height: 25%;
    box-sizing: border-box;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
        margin: 0 auto;
        width: auto;
        height: 95%;
    }
}

.series__carrousel {
    height: 75%;
}

.series-item {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 25%;
    overflow: hidden;
    border-bottom: .1rem dotted var(--color-border-dark);

    &:last-child {
        border: 0;
    }

    &.-h-1, &.-h-2, &.-h-3 {
        height: 33.3333%;
    }
}

.series-item__content {
    float: left;
    width: 75%;
    height: 100%;
    padding: .56rem;

    @media screen and (min-width: 768px) {
        padding: .72rem 1.44rem;
    }

    box-sizing: border-box;
    font-size: 0;
    line-height: 1.25;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.series-item__date {
    font-family: var(--font-general);
    font-size: calc(14px + 1vw);
    font-weight: var(--font-weight-regular);
    line-height: 1;
    text-transform: uppercase;
    margin: 0 0 .39rem 0;
}

.series-item__title {
    margin: 0;
    display: inline;
    font-family: var(--font-general);
    font-size: calc(21px + 1.5vw);
    line-height: 1;

    @media screen and (min-width: 768px) {
        line-height: 1.15;
    }

    font-weight: var(--font-weight-medium);
    text-transform: uppercase;
}

.series-item__timing {
    margin: 0;
    display: inline;
    font-family: var(--font-general);
    font-size: calc(14px + 1vw);
    line-height: 1;

    @media screen and (min-width: 768px) {
        line-height: 1.15;
    }

    font-weight: var(--font-weight-regular);
}

.series-item__tickets {
    float: left;
    width: 25%;
    height: 100%;

    padding: .56rem;

    @media screen and (min-width: 768px) {
        padding: .72rem 1.44rem;
    }

    text-align: right;
    box-sizing: border-box;

    font-size: calc(8px + 0.7vw);
    color: var(--color-purple);

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.series-item__price {
    font-weight: var(--font-weight-medium);
    color: var(--color-purple);
    font-size: calc(11px + 1vw);
    font-family: var(--font-general);
    display: block;
    margin-bottom: .39rem;

    svg {
        width: 1.17rem;
        height: 1.17rem;
        line-height: 1;
        vertical-align: middle;
        position: relative;
        top: -.16rem;
        fill: var(--color-purple);
    }

    &.-sold_out, &.-full, &.-cancelled {
        color: var(--color-black);
        font-weight: var(--font-weight-regular);

        svg {
            fill: var(--color-black);
        }
    }
}
