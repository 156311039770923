@use '../../node_modules/@splidejs/splide/dist/css/splide.min.css';

.info-cards__container {
    padding: 4rem 0;
    background: var(--color-infocard);

    @media (--screen-large) {
        padding: 10rem 0 2rem;
    }

    &.-transparent {
        background: none;
        padding: 0 0 4rem;

        @media (--screen-large) {
            padding: 0 0 10rem;
        }
    }

    &.-text {
        padding: 4rem 0;
    }

    &.-bottom {
        padding-bottom: 4rem;
    }

    .body.-abatnight & {
        background-color: var(--color-abatnight-body);
        color: var(--color-abatnight-white);
    }

    .myab__header.-artists & {
        padding: 2rem 0 0;

        @media (--screen-large) {
            padding: 6rem 0 0;
        }
    }
}

.info-cards__title {
    color: var(--color-purple);
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    display: block;
    text-align: center;
    font-size: 2.8rem;
    line-height: 1.25;
    margin: 0 auto;
    max-width: 26rem;

    @media (--screen-large) {
        font-size: 3.4rem;
        max-width: 100%;
    }

    .info-cards__container.-transparent & {
        margin-bottom: 3rem;
    }

    .body.-abatnight & {
        color: var(--color-abatnight-white);
    }
}

.info-cards__lists {
    margin: 0 0 0 calc(var(--side-padding) * -1);
    padding: 0;
    list-style: none;

    &:not(.splide__list) {
        width: 100%;
        max-width: 128rem;
        margin: 0 auto;
        padding: 0 var(--side-padding);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        box-sizing: border-box;

        li {
            flex-basis: 39rem;
        }

        .info-card {
            @media (--screen-mini) {
                padding-left: 0;
                padding-right: 0;

                img {
                    max-width: 100%;
                }
            }
        }
    }

    &.-border {
        margin: 0;
        padding: 0;
        gap: 3rem;

        @media (--screen-large) {
            display: flex;
            justify-content: center;
        }

        li {
            padding: 0;
        }
    }
}

.info-card {
    box-sizing: border-box;
    max-width: 39rem;
    text-align: center;
    padding: 3rem 2.5rem;
    margin: 0 auto;

    @media (--screen-large) {
        padding: 6rem 2.5rem;

        .info-cards__container.-text & {
            padding: 3rem 2.5rem;
        }
    }

    a {
        text-decoration: none;
        color: var(--color-purple);

        img, .info-card__title, p {
            transition: opacity .3s ease;
        }

        &:hover,
        &:focus {
            img, .info-card__title, p {
                opacity: .55;
            }
        }

        .body.-abatnight & {
            color: var(--color-abatnight-white);
        }
    }

    img {
        vertical-align: bottom;
        margin-bottom: 2rem;
        inline-size: revert-layer;
        block-size: revert-layer;

        .body.-abatnight & {
            filter: grayscale(1) invert(1);
        }
    }

    p {
        margin-bottom: 3rem;

        &.-nm {
            margin-bottom: 0;
        }
    }

    .info-cards__lists.-border & {
        border: .1rem dotted var(--color-border-dark);
        height: 100%;
    }
}

.info-card__title {
    display: block;
    text-align: center;
    color: var(--color-purple);
    font-size: 2.8rem;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    margin: 0 0 1rem;
    transition: opacity .3s ease;

    .body.-abatnight & {
        color: var(--color-abatnight-white);
    }
}

.splide__list {
    will-change: transform;
}

.splide__pagination {
    display: flex;
    justify-content: center;
    position: static;
    transform: translate(0);
    width: 100%;
    padding: 0 !important;

    @media (--screen-large) {
        display: none;
    }
}

.splide__pagination__page {
    width: 1.4rem;
    height: 1.4rem;
    background: var(--color-title);
    border-radius: 50%;
    margin: 0 1.2rem;
    opacity: 1;

    &.is-active {
        background: var(--color-purple) !important;
        transform: scale(1);
    }
}

.splide--fade > .splide__track > .splide__list > .splide__slide {
    height: 100%;
}
