.search__title {
    color: var(--color-purple);
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    display: block;
    text-align: center;
    font-size: 3.4rem;
    line-height: 1.25;
    margin: 0 0 3rem;

    @media (--screen-large) {
        font-size: 4.3rem;
    }
}
