.row[data-block-type='button-row'] {
    padding-block: 5rem;
    background-position: top center;

    &[data-theme='fun'] {
        background-image: url('../../images/bg-fun.png');
    }

    &[data-theme='family'] {
        background-image: url('../../images/bg-family.png');
    }

    &[data-theme='rebellious'] {
        background-image: url('../../images/bg-rebellious.png');
    }

    &[data-theme='well-informed'] {
        background-image: url('../../images/bg-well-informed.png');
    }
}

.button-row {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: flex-start;

    margin: 0 0 3.6rem;

    &.-centered, [data-alignment='centered'] & {
        justify-content: center;
    }

    .row[data-block-type='button-row'] & {
        margin-bottom: 0;
    }

    > * {
        flex: 0 0 auto;
    }
}
