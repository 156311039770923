:root {
    --outer-container-max-width: 192rem;
    --inner-container-max-width: 160rem;
    --site-padding: 2rem;
}

@mixin container() {
    inline-size: 100%;
    margin-inline: auto;
}

%container {
    @include container();
}

/* Max site width without padding */
@mixin outer() {
    @extend %container;

    max-inline-size: var(--outer-container-max-width);
}

/* Max content width with side padding */
@mixin inner() {
    @extend %container;

    max-inline-size: var(--inner-container-max-width);
    padding-inline: var(--site-padding);
}
