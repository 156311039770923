@use '../../elements/button/button';

.faq-item {
    border: 0 solid var(--color-faq);
    border-block-end-width: .1rem;
}

.faq-item__title, h3.faq-item__title {
    cursor: pointer;

    position: relative;

    display: block;

    margin: 0;
    padding: 1.7rem 4rem 1.6rem 0;

    font-size: 2.5rem;
    font-weight: var(--font-weight-regular);
    color: var(--color-purple);
    line-height: 1.44;
    text-align: start;

    &::after {
        content: '';

        position: absolute;
        inset-block-start: 50%;
        inset-inline-end: .5rem;
        transform: translateY(-50%);

        display: block;

        inline-size: 2.2rem;
        block-size: 2.2rem;

        background-color: var(--color-purple);

        mask-image: url('../icon/arrow-down.svg');
        mask-repeat: no-repeat;
        mask-size: contain;
    }

    .faq-item.-open &::after {
        transform: translateY(-50%) rotate(180deg);
    }
}

.faq-item__content {
    display: none;
    margin: 0;
    padding-block-end: 1.7rem;
    font-size: 1.8rem;

    .faq-item.-open &, [data-gjs-type='wrapper'] & {
        display: block;
    }

    > *:first-child {
        margin-block-start: 0;
    }

    > *:last-child {
        margin-block-end: 0;
    }
}
