.anchors {
    position: sticky;
    z-index: 5;
    inset-block-start: 0;

    margin-block-end: 5rem;
    padding: 1.6rem 1rem;

    font-size: 2.5rem;
    line-height: 1.4;

    background-color: var(--color-white);

    & > .dropdown {
        box-sizing: border-box;
        inline-size: 100%;
        max-inline-size: 80rem;
        margin-inline: auto;
    }

    @media (--screen-medium-large) {
        margin-block-end: 10.7rem;
    }
}

.anchors__trigger {
    background: var(--color-purple);
    border-radius: 20px;

    &::after {
        display: none;
    }
}

.anchors__active {
    display: inline-flex;
    justify-content: space-between;

    box-sizing: border-box;
    inline-size: 100%;
    padding: 1.2rem 1.6rem;

    color: var(--color-general);

    .anchors__trigger.-open & > span {
        visibility: hidden;
    }
}

.anchors__list {
    pointer-events: none;

    position: relative;
    z-index: -1;

    max-block-size: 0;
    margin: 0;
    padding: 0;

    list-style: none;

    opacity: 0;

    .anchors__trigger.-open & {
        pointer-events: auto;
    }
}

.anchors__item {
    padding: 0 1.6rem 1.2rem;
}

.anchors__link {
    color: var(--color-general-darker);
    text-decoration: none;

    &:--focus, &.-active {
        color: var(--color-general);
    }
}
