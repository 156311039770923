figure.table {
    margin: 2rem 0;
    overflow: auto;
    border: .1rem solid var(--color-general);

    .event-detail__content.-family & {
        border-color: var(--color-family);
    }

    .event-detail__content.-fun & {
        border-color: var(--color-fun);
    }

    .event-detail__content.-rebellious & {
        border-color: var(--color-rebellious);
    }

    .event-detail__content.-well-informed & {
        border-color: var(--color-well-informed);
    }

    table {
        border: 0;
    }

    .-teampage & {
        border: 0;
        margin: 2rem 0 3.8rem;
    }
}

table:not([class]) {
    min-width: 100%;
    border: .1rem solid var(--color-general);
    border-collapse: collapse;

    .event-detail__content.-family & {
        border-color: var(--color-family);
    }

    .event-detail__content.-fun & {
        border-color: var(--color-fun);
    }

    .event-detail__content.-rebellious & {
        border-color: var(--color-rebellious);
    }

    .event-detail__content.-well-informed & {
        border-color: var(--color-well-informed);
    }

    th, td {
        color: var(--color-purple);
        font-family: var(--font-general);
        font-size: 1.8rem;
        font-weight: var(--font-weight-regular);
        text-align: left;
        vertical-align: top;
        padding: 1.8rem;
        border: solid var(--color-general);
        border-width: .1rem 0 0 .1rem;
        box-sizing: border-box;
        max-width: 28rem;

        @media (--screen-mini-small) {
            font-size: 1.6rem;
            padding: .8rem;
            width: 50%;
        }

        @media (--screen-mini-medium) {
            min-width: 13rem;
        }

        .event-detail__content.-family & {
            border-color: var(--color-family);
        }

        .event-detail__content.-fun & {
            border-color: var(--color-fun);
        }

        .event-detail__content.-rebellious & {
            border-color: var(--color-rebellious);
        }

        .event-detail__content.-well-informed & {
            border-color: var(--color-well-informed);
        }

        .-teampage & {
            border: 0;
            padding-top: 0;
            padding-left: 0;

            @media (--screen-mini-small) {
                padding: 0;
                width: 100%;
                display: block;
            }
        }
    }

    th {
        background: var(--color-infocard);
        border-width: 0 0 .1rem .1rem;
        font-weight: var(--font-weight-medium);

        .event-detail__content.-family & {
            background: var(--color-family-bg);
        }

        .event-detail__content.-fun & {
            background: var(--color-fun-bg);
        }

        .event-detail__content.-rebellious & {
            background: var(--color-rebellious-bg);
        }

        .event-detail__content.-well-informed & {
            background: var(--color-well-informed-bg);
        }
    }

    tr {
        td:first-child, th:first-child {
            border-left: 0;
        }

        &:first-child {
            td, th {
                border-top: 0;
            }
        }
    }

    .-teampage & {
        border: 0;
    }
}
