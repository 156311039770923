.file-list {
    margin: 3rem 0 5rem;
    padding: 0;

    list-style: none;

    border: 0 solid var(--color-border);
    border-block-start-width: .2rem;
    border-block-end-width: .2rem;
}

.file-list > li {
    margin: 0;
    padding: 0;
    border: 0 solid var(--color-border);
    border-block-end-width: .2rem;

    &:last-child {
        border-block-end-width: 0;
    }
}
