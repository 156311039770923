%form__field-reset {
    transition: all .2s ease;
    box-sizing: border-box;
    border-radius: 0;

    -webkit-appearance: none !important; // HARD reset to remove shadow in input for IOS

    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 1.5rem .1rem;
    vertical-align: baseline;

    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    font-size: 1.6rem;
    line-height: 1.5;

    color: var(--color-purple);
    background: transparent;
    border: solid var(--color-black);
    border-width: 0 0 .1rem;

    // Firefox outline style
    outline: none;
}

// FF cursor pointer bug fix
input {
    cursor: pointer;
}

.form__select {
    background-image: url(select.svg);
    background-position: center right;
    background-repeat: no-repeat;

    .form__entry & {
        order: 2;
    }

    select {
        @extend %form__field-reset;
    }
}

.form__field {
    @extend %form__field-reset;

    &:focus {
        outline: none;
        border-color: var(--color-black);
    }

    &::placeholder {
        color: inherit;
        font-family: inherit;
        font-weight: inherit;
        font-size: inherit;
        opacity: .5;
    }

    &.-textarea {
        resize: vertical;
    }

    &.-page-search {
        padding: 1.5rem 2.5rem;
        border: 0;
        background: var(--color-white);
        border-radius: 2.5rem;
        height: 5rem;
        max-width: calc(100% - 7rem);
        margin-right: 1.5rem;

        .page-search.-inline & {
            border: .1rem solid var(--color-purple);
        }
    }

    &.-newsletter {
        border: dashed var(--color-general);
        border-width: 0 0 .1rem;
        color: var(--color-general);

        .newsletter & {
            border-color: var(--color-purple);
            color: var(--color-purple);
        }
    }

    .form__entry.-invalid & {
        border-color: var(--color-red);

        // Firefox outline style & shadow
        outline: none;
        box-shadow: none;
    }

    &.-has-value,
    &[data-component='form/hasValue']:focus,
    &[type='date'] {
        & ~ .form__label {
            font-size: 1.2rem;
            transform: translateY(-.5rem);
        }
    }

    &.-select {
        background-image: url(select.svg);
        background-position: center right;
        background-repeat: no-repeat;
    }

    &.-file {
        order: 2;
        border: 0;

        & ~ .form__label {
            position: relative;
            top: auto;
            margin-bottom: 1.5rem;
        }
    }

    &.-radio {
        display: none;

        + label {
            cursor: pointer;
            position: relative;
            display: inline-block;
            padding: 0 0 0 3.2rem;
            margin-right: 4rem;

            color: var(--color-purple);
            font-family: var(--font-general);
            font-weight: var(--font-weight-regular);
            font-size: 1.6rem;
            line-height: 1.4;

            text-align: left;

            opacity: 1;

            &::before,
            &::after {
                content: '';

                display: block;
                width: 2rem;
                height: 2rem;

                position: absolute;
                top: 0;
                left: 0;
            }

            &::before {
                transition:
                    box-shadow .2s ease,
                    background-color .2s ease;
                box-shadow: inset 0 0 0 .2rem var(--color-purple);
                border-radius: 50%;
            }

            &::after {
                background-color: var(--color-purple);
                border-radius: 50%;
                opacity: 0;
                transform: scale(0);
            }
        }

        &:checked + label::before {
            box-shadow: inset 0 0 0 .2rem var(--color-purple);
            background-color: var(--color-white);
        }

        &:checked + label::after {
            opacity: 1;
            transform: scale(.6);
        }
    }
}

