.row[data-block-type='slider']:not([data-amount="3"]) {
    background-color: var(--color-general);
    padding-block: 7.5rem;

    .splide__pagination {
        display: flex;
        bottom: -3rem;
    }

    .slider {
        max-inline-size: 100rem;
        margin-inline: auto;
    }

    @media (--screen-from-xl) {
        .splide__arrow--prev {
            inset-inline-start: -10rem;
        }

        .splide__arrow--next {
            inset-inline-end: -10rem;
        }
    }
}

.row[data-block-type='slider'] {
    .slide__image {
        object-fit: contain;
        object-position: center;
    }
}

.slider__title {
    font-weight: var(--font-weight-medium);
    max-inline-size: 128rem;
    margin-inline: auto;
    padding-inline: var(--site-padding);
}
