.audio-player {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    background: var(--color-general);
    position: absolute;
    bottom: 0;
    right: 0;
    text-decoration: none;
    text-align: center;
    line-height: 5rem;
    border: 0;
    cursor: pointer;
    outline: none;
    transition: background-color .3s ease;
    z-index: 1;

    .event-detail__header__info & {
        background: var(--color-purple);
        transition: opacity .3s ease;

        .body.-abatnight & {
            background: var(--color-abatnight-white);
        }
    }

    .body.-abatnight & {
        background: var(--color-abatnight-white);
    }

    &.-top {
        top: 0;
        bottom: auto;
    }

    .icon.-audio {
        display: inline-block;
    }
    .icon.-cross {
        display: none;
    }

    &.-playing {
        .icon.-cross {
            display: inline-block;
        }
        .icon.-audio {
            display: none;
        }
    }

    &:hover, &:focus {
        background: var(--color-purple);

        .content-card.-family & {
            background: var(--color-family);
        }

        .content-card.-fun & {
            background: var(--color-fun);
        }

        .content-card.-rebellious & {
            background: var(--color-rebellious);
        }

        .content-card.-well-informed & {
            background: var(--color-well-informed);
        }

        .content-card.-abatnight & {
            background: var(--color-abatnight-white);
        }

        .event-detail__header__info & {
            opacity: .6;

            .body.-abatnight & {
                opacity: 1;
            }
        }

        .body.-abatnight & {
            background: var(--color-abatnight);
        }
    }
}
