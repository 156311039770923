.agora__carrousel {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: var(--color-general);

    .carrousel {
        background-color: var(--color-general);
        top: 15%;
        height: 85%;
        padding: 2rem 4rem;
        box-sizing: border-box;
    }
}

.agora-item {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 25%;
    overflow: hidden;
    border-top: .5rem dotted var(--color-border-dark);
    padding: 2rem 0;
    position: relative;

    &:first-child {
        border: 0;
    }

    &.-h-1, &.-h-2, &.-h-3 {
        height: 33.3333%;
    }

    &.-h-4 {
        height: 25%;
    }

    &.-agenda {
        height: calc(100% / 8);
    }
}

.agora-item__img {
    float: left;
    width: 17%;
    height: 100%;
    background-size: cover;
    box-sizing: border-box;

    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.agora-item__content {
    position: relative;
    float: left;
    width: calc(83% - 30rem);
    height: 100%;
    padding: 1rem 4rem;

    box-sizing: border-box;
    font-size: calc(18px + 2vw);
    line-height: 1.25;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);

    display: flex;
    flex-direction: column;

    .agora-item.-agenda & {
        width: calc(83% - 20rem);
    }
}

.agora-item__date {
    font-family: var(--font-general);
    font-size: 3rem;
    font-weight: var(--font-weight-regular);
    line-height: 1;
    text-transform: uppercase;
    margin: 0 0 2rem;

    small {
        display: block;
        margin: .56rem 0;

        @media screen and (min-width: 768px) {
            position: relative;
            display: inline-block;
            top: -.17rem;
            margin: 0 0 0 .56rem;
        }

        text-transform: none;
        font-size: calc(13px + .6vw);
        line-height: 1;
        font-family: var(--font-general);

        svg {
            position: relative;
            top: -.17rem;
            line-height: 1;
            fill: var(--color-black);
            width: 1.33rem;
            height: 1.33rem;
            vertical-align: middle;
        }

        &.-buy, &.-subscribe_site, &.-external_sale, &.-free {
            font-weight: var(--font-weight-medium);
            color: var(--color-purple);

            svg {
                fill: var(--color-purple);
            }
        }
    }

    .agora-item.-agenda & {
        font-size: 2.5rem;
    }
}

.agora-item__title {
    margin: 1rem 0 0 0;
    font-family: var(--font-general);
    font-size: 4rem;
    line-height: 1;

    font-weight: var(--font-weight-medium);

    .agora-item.-agenda & {
        font-size: 3.5rem;
    }
}

.agora-item__timing {
    margin: 1rem 0 0 0;
    font-family: var(--font-general);
    font-size: 3rem;
    line-height: 1;

    font-weight: var(--font-weight-regular);

    .agora-item.-agenda & {
        font-size: 2.5rem;
    }
}

.agora-item__provider {
    max-width: 20%;
    text-align: right;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: .72rem;
}

.agora-item__inner {
    & > img {
        max-width: 10rem;
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}

.agora-header {
    padding: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.agora-header__title {
    font-family: var(--font-rebellious);
    font-size: 10rem;
    letter-spacing: -.4rem;
    margin: 0;
}

.agora-header__info {
    font-size: 3rem;
}

.agore-item__button {
    position: absolute;
    top: 50%;
    right: 0;
    border-radius: 10rem;
    border: .2rem solid var(--color-purple);
    width: 30rem;
    text-align: center;
    font-size: 3rem;
    padding: 1rem;
    transform: translateY(-50%);
    box-sizing: border-box;

    &.-soldout {
        background-color: #ad8e9e;
        border-color: #ad8e9e;
        color: var(--color-general);
    }

    .agora-item.-agenda & {
        font-size: 2.2rem;
        width: 20rem;
    }
}
