html {
    font-size: 62.5%; // base for rem units - for ±97% of all browsers this equates to 10px
    line-height: 1;

    box-sizing: border-box;
    width: 100%;
    min-width: 320px;
    margin: 0 auto;

    background: var(--color-white); // set to overwrite Firefox themes

    touch-action: manipulation; // remove 350ms delay on ios

    &.zebrix, &.zebrix body {
        width: 100%;
        height: 100%;
    }
}
