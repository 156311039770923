.read-more__target {
    display: block;
    max-height: 0;
    overflow: hidden;

    &.-active {
        max-height: none;
    }
}

.read-more__trigger {
    &.-active {
        display: none;
    }

    .read-more__target & {
        margin-top: 4rem;

        &:first-child {
            margin-top: 0;
        }
    }
}
