.sticky-bar {
    background: var(--color-sticky-purple);
    padding: 2rem;
    display: block;
    text-align: center;
    position: relative;

    &.-type-1 {
        background: var(--color-sticky-green);
    }

    @media (--screen-large) {
        text-align: left;

        .site-container {
            position: relative;
            display: flex;
            align-items: center;
        }

        p {
            display: inline-block;
            margin: 0 2rem 1rem 0;
        }

        .sticky-bar__content {
            margin: 0 4rem 0 2rem;
        }
    }

    img {
        inline-size: 4rem;
        block-size: 4rem;
    }
}

.sticky-bar__close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: 2rem;
    height: 2rem;
    border: 0;
    font: 0/0 sans-serif;
    cursor: pointer;

    background-color: var(--color-purple);
    background-image: url(cross.svg);
    background-size: 2rem 2rem;

    transition: opacity .3s ease;

    @supports (mask-repeat: no-repeat) {
        background: var(--color-purple);
        mask-image: url(cross.svg);
        mask-repeat: no-repeat;
        mask-size: 2rem 2rem;
    }

    &:hover,
    &:focus {
        opacity: .6;
    }

    @media (--screen-large) {
        top: 1.5rem;
        right: 0;
    }
}
