.form__actions {
    padding-top: 2rem;

    &.delete__account_buttons {
        @media (--screen-large) {
            margin: 0 -6rem;
        }

        .button {
            margin: 1rem;
        }
    }
}

.form__actions__spacer {
    padding: 2rem 0;

    &.-line {
        border-top: .1rem dotted var(--color-border-dark);
        margin: 4rem -2rem 0;

        @media (--screen-large) {
            margin: 4rem -7rem 0;
        }
    }
}
