@import '../../node_modules/@splidejs/splide/src/css/themes/default';

.splide {
    // only in pagebuilder
    [data-gjs-type='wrapper'] & {
        visibility: visible;
    }
}

.splide__list {
    block-size: auto;

    // only in pagebuilder
    [data-gjs-type='wrapper'] & {
        flex-wrap: wrap;
    }
}

.splide__arrows {
    // only in pagebuilder
    [data-gjs-type='wrapper'] & {
        display: none;
    }
}

.splide__arrow {
    inline-size: 4.8rem;
    block-size: 4.8rem;
    border-radius: 50%;

    font-size: 0;

    opacity: 1;
    background-color: var(--color-general);

    transition: background-color .2s ease;

    .splide:has(.slider__title) & {
        transform: translateY(2rem);
    }

    &::before {
        content: '';

        display: inline-block;

        inline-size: 4.8rem;
        block-size: 4.8rem;

        background-color: var(--color-purple);
        mask-image: url('../icon/arrow-right.svg');
        mask-size: 2.1rem 2.1rem;
        mask-repeat: no-repeat;
        mask-position: center;
    }

    &:--focus {
        background-color: var(--color-general-hover);
    }
}

.splide__arrow--prev {
    inset-inline-start: 1rem;

    &::before {
        transform: rotate(180deg);
    }
}

.splide__arrow--next {
    inset-inline-end: 1rem;
}

@media (--screen-from-md) {
    .splide__arrow--prev {
        inset-inline-start: 2rem;
    }

    .splide__arrow--next {
        inset-inline-end: 2rem;
    }
}
