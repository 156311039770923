.card-rounded {
    display: flex;
    flex: 0 1 20em;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;

    text-decoration: none !important;

    [data-theme='fun'] &,
    [data-theme='family'] &,
    [data-theme='rebellious'] &,
    [data-theme='well-informed'] & {
        padding: 3rem;
        background-color: var(--color-white);
    }

    &:not([href]), &[href=""], &[href="#"] {
        pointer-events: none;

        // only in pagebuilder
        [data-gjs-type='wrapper'] & {
            pointer-events: auto;
        }
    }
}

.card-rounded__figure {
    overflow: hidden;

    inline-size: 20rem;
    block-size: 20rem;
    margin-block: 2.2rem;

    border-radius: 50%;
}

.card-rounded__info {
    padding: 1.6rem 0 0 0;
    text-align: center;
}

.card-rounded__title {
    margin: 0;
}

.card-rounded__description {
    margin-block: 3.2rem 0;
}

.card-rounded__button {
    margin-block: 3.2rem 0;
}
