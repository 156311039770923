.pagination-list {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    position: fixed;
    bottom: 4rem;
    left: 0;
    width: 100%;
}

.pagination-list__item {
    display: inline-block;
    border-radius: 50%;
    background: var(--color-purple);
    width: 1rem;
    height: 1rem;
    margin: .5rem;
    font-size: 0;
    opacity: .25;
    pointer-events: none;

    &.-active {
        opacity: 1;
    }
}
