.wrapper {
    &.-purple {
        background-color: var(--color-purple);
    }

    &.-general {
        background-color: var(--color-general);
    }

    &.-info {
        background-color: var(--color-infocard);
    }

    .text + & {
        margin: 0 0 3rem;
    }

    .page-detail__content__info & {
        margin: 0 calc(var(--side-padding) * -1);

        + .text {
            margin-top: 3rem;
        }
    }
    .page-detail__content__info .text + & {
        margin: 0 calc(var(--side-padding) * -1) 3rem;
    }
}
