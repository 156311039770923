.cta__container {
    padding: 0 0 10rem;

    &.-myab {
        padding: 2rem 0 4rem;
    }

    &.-center {
        padding: 4rem 0;
        text-align: center;
    }

    &.-bottom {
        padding: 0 0 3rem;
    }

    &.-mobile {
        display: block;

        @media (--screen-full) {
            display: none;
        }
    }

    &.-desktop {
        display: none;

        @media (--screen-full) {
            display: block;
        }
    }
}

.cta {
    display: flex;
    align-items: center;
    transition: all .3s ease;
    text-decoration: none;

    flex-direction: column;

    @media (--screen-full) {
        flex-direction: row;
    }

    background-color: var(--color-general);

    &.-fun {
        background-color: var(--color-fun);
    }

    &.-family {
        background-color: var(--color-family);
    }

    &.-well-informed {
        background-color: var(--color-well-informed);
    }

    &.-rebellious {
        background-color: var(--color-rebellious);
    }

    &:hover, &:focus {
        background-color: var(--color-general-bg);

        &.-fun {
            background-color: var(--color-fun-bg);
        }

        &.-family {
            background-color: var(--color-family-bg);
        }

        &.-well-informed {
            background-color: var(--color-well-informed-bg);
        }

        &.-rebellious {
            background-color: var(--color-rebellious-bg);
        }
    }

    .body.-abatnight & {
        background-color: var(--color-abatnight-bg);
    }
}

.cta__title {
    max-width: 48rem;
    margin: 4rem 2rem;
    font-size: 2.6rem;
    color: var(--color-purple);
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    text-align: center;
    line-height: 1.3 ;

    .body.-abatnight & {
        color: var(--color-abatnight-body);
    }

    @media (--screen-small-large) {
        margin: 4rem;
    }

    @media (--screen-full) {
        margin: 0 0 0 6rem;
        text-align: left;
        font-size: 2.8rem;
        max-width: 46rem;
    }
}

.cta__update {
    margin: 0 0 3rem;
    color: var(--color-purple);
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    font-size: 1.6rem;

    @media (--screen-full) {
        margin: 0 5rem 0 auto;
    }
}

.cta__list {
    margin: 0 0 3rem;
    list-style: none;
    padding: 0;
    display: block;

    @media (--screen-full) {
        margin: 0 5rem 0 auto;
    }

    .cta__list__item {
        display: inline-block;
        width: 5rem;
        height: 5rem;
        background: var(--color-purple);
        border-radius: 50%;
        text-align: center;
        line-height: 5rem;
        margin-right: 1.5rem;
        font-size: 0;

        &:last-child {
            margin-right: 0;
        }

        .body.-abatnight & {
            background: var(--color-abatnight-body);
        }
    }
}

.cta__button {
    padding: 5rem 2rem;
    background: url('../images/general.svg') no-repeat 45% 55%;
    background-size: 250rem 250rem;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    overflow: hidden;

    @media (--screen-full) {
        margin-left: auto;
        width: auto;
        padding: 5rem 4rem;
    }

    @media (--screen-full) {
        padding: 5rem 6rem;
        margin-left: 0;
    }

    .cta.-fun & {
        background-image: url('../images/fun.svg');
    }

    .cta.-family & {
        background-image: url('../images/family.svg');
    }

    .cta.-rebellious & {
        background-image: url('../images/rebellious.svg');
    }

    .cta.-well-informed & {
        background-image: url('../images/well-informed.svg');
    }

    .button {
        white-space: nowrap;
    }
}
