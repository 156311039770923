.page-detail__header {
    position: relative;

    .site-container {
        position: relative;
        z-index: 1;
    }
}

.page-detail__header__img {
    display: block;
    width: 100%;
    max-width: 144rem;
    margin: 0 auto 2rem auto;
    height: auto;
    min-height: 35rem;
    max-height: 60rem;
    object-fit: cover;

    @media (--screen-large) {
        margin: 0 auto 5rem auto;
    }
}

.page-detail__header__title {
    font-weight: var(--font-weight-medium);
    font-family: var(--font-general);
    font-size: 4.6rem;
    line-height: 1.3;
    max-width: 75rem;
    margin: 0 auto 2rem;
    color: var(--color-purple);

    @media (--screen-large) {
        margin: 0 auto 5rem;

        & + .page-detail__header__info {
            margin-top: -3rem;
        }
    }
}

.page-detail__header__info {
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    font-size: 1.7rem;
    color: var(--color-purple);
    opacity: .6;
    max-width: 75rem;
    margin: 0 auto 2rem;
    display: block;

    @media (--screen-large) {
        font-size: 2.1rem;
        margin: 0 auto 5rem;
    }

    &.-center {
        text-align: center;
    }
}

.page-detail__content {
    box-sizing: border-box;

    + .page-detail__content {
        margin-top: -2rem;
    }

    &.--anchor {
        scroll-margin-top: 10rem;

        @media (--screen-large) {
            scroll-margin-top: 14rem;
        }
    }
}

.page-detail__content__info {
    width: 100%;
    box-sizing: border-box;
    padding: 2rem 0;
    color: var(--color-purple);
    font-family: var(--font-general);
    font-size: 1.8rem;
    font-weight: var(--font-weight-regular);

    a:not([class]) {
        color: var(--color-purple);
        text-decoration: underline;
        font-family: var(--font-general);
        font-weight: var(--font-weight-medium);

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}
