.form__entry {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    &.-checkbox, &:has(.form__checkbox) {
        margin-top: 4rem;

        & + .form__entry.-checkbox, & + .form__entry:has(.form__checkbox) {
            margin-top: 2rem;
        }

        .page-footer__newsletter__input &, .newsletter__input & {
            margin: 1rem 0 0;
        }
    }

    &.-radio {
        margin-top: 4rem;

        div {
            text-align: left;
        }
    }

    &.-inline {
        margin-top: 2rem;
    }

    &.-hidden {
        display: none;
    }
}
