.form__radio {
    border: 0;
    display: none;

    + .form__label {
        position: relative;
        margin-bottom: 1rem;
        white-space: normal !important;

        &::before,
        &::after {
            content: '';

            display: block;
            width: 2rem;
            height: 2rem;

            position: absolute;
            top: 0;
            left: 0;

            border-radius: 50%;
        }

        &::before {
            transition:
                box-shadow .2s ease,
                background-color .2s ease;
            box-shadow: inset 0 0 0 .2rem var(--color-purple);
        }

        &::after {
            transition:
                opacity .3s ease,
                transform .2s ease;

            z-index: 1;
            opacity: 0;
            transform: scale(1);

            width: 1rem;
            height: 1rem;

            top: .5rem;
            left: .5rem;

            background: var(--color-purple);
        }
    }

    &:checked + .form__label::before {
        box-shadow: inset 0 0 0 .2rem var(--color-purple);
        background-color: var(--color-white);
    }

    &:checked + .form__label::after {
        opacity: 1;
        transform: scale(1);
    }

    &:disabled + .form__label::before {
        background-color: var(--color-general);
    }

    &:disabled + .form__label::before, &:disabled + .form__label::after {
        opacity: .4;
    }
}
