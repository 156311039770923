.myab-login {
    background-image: url(../images/general.svg);
    background-repeat: no-repeat;
    background-position: 50% -124rem;
    background-size: 250rem 250rem;
    padding: 10rem 0 3rem;

    @media (--screen-large) {
        padding: 10rem 0;
    }
}

.myab__box {
    background: var(--color-white);
    margin: 0 auto;
    width: 100%;
    max-width: 54rem;
    padding: 2rem 2rem 0;
    box-sizing: border-box;
    text-align: center;

    @media (--screen-large) {
        padding: 3rem 7rem 0;
    }

    &.-padded {
        padding-bottom: 2rem;

        @media (--screen-large) {
            padding-bottom: 4rem;
        }
    }

    &.-top {
        padding-top: 0;
    }

    &.-form {
        padding-left: 0;
        padding-right: 0;
    }

    a:not(.form__link) {
        color: var(--color-purple);
        text-decoration: underline;
        font-family: var(--font-general);
        font-weight: var(--font-weight-medium);

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    &.-loading {
        position: relative;

        &::before {
            content: '';
            background: url(refresh.svg);
            display: block;
            width: 10rem;
            height: 10rem;
            background-size: 10rem 10rem;
            position: fixed;
            top: 50%;
            left: 50%;
            margin: -5rem 0 0 -5rem;
            z-index: 2;
            animation-duration: 1.5s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            transform-origin: center center;
            will-change: transform;
            animation-name: turn;

            @media (--screen-medium-large) {
                position: absolute;
            }
        }

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            background: var(--color-general);
            opacity: .4;
            display: block;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;

            @media (--screen-medium-large) {
                position: absolute;
            }
        }
    }
}

.myab__box__title {
    display: block;
    text-align: center;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    font-size: 4.3rem;
    margin-bottom: 3rem;
}


@keyframes turn {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}
