.page__title {
    margin-top: 6rem;

    @media (--screen-medium-large) {
        margin-top: 10rem;
    }

    &.-center {
        text-align: center;
    }
}

h1 {
    margin: 0 0 3rem;
    font-size: 4.2rem;
    font-family: var(--font-general);
    font-weight: var(--font-weight-medium);
    color: var(--color-purple);
    line-height: 1.3;

    @media (--screen-large) {
        font-size: 6.8rem;
    }

    .body.-abatnight &:not([class]) {
        color: var(--color-abatnight-white);
    }
}

h2 {
    margin: 0 0 3rem;
    font-size: 3.5rem;
    font-family: var(--font-general);
    font-weight: var(--font-weight-medium);
    color: var(--color-purple);
    line-height: 1.3;

    @media (--screen-large) {
        font-size: 4.3rem;
    }

    .body.-abatnight &:not([class]) {
        color: var(--color-abatnight-white);
    }
}

h3 {
    margin: 0 0 3rem;
    font-size: 2.9rem;
    font-family: var(--font-general);
    font-weight: var(--font-weight-medium);
    color: var(--color-purple);
    line-height: 1.3;

    @media (--screen-large) {
        font-size: 3.5rem;
    }

    .body.-abatnight &:not([class]) {
        color: var(--color-abatnight-white);
    }
}

h4 {
    margin: 0 0 3rem;
    font-size: 2.1rem;
    font-family: var(--font-general);
    font-weight: var(--font-weight-medium);
    color: var(--color-purple);
    line-height: 1.3;

    @media (--screen-large) {
        font-size: 2.8rem;
    }

    &.-top {
        margin-top: 6rem;
    }

    .body.-abatnight &:not([class]) {
        color: var(--color-abatnight-white);
    }
}

h5 {
    margin: 0 0 1.5rem;
    font-size: 1.4rem;
    font-family: var(--font-general);
    font-weight: var(--font-weight-medium);
    color: var(--color-purple);
    text-transform: uppercase;
    letter-spacing: .3rem;

    @media (--screen-large) {
        font-size: 1.8rem;
    }

    .body.-abatnight &:not([class]) {
        color: var(--color-abatnight-white);
    }
}
